import { Card, CardContent, CardHeader, Dialog, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material"
import * as React from "react"
import { InstitutionDegree } from "../../../../../../models"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useState } from "react";
import EditAudition from "./EditAudition";
import RichTextViewer from "../../../../../controls/RichTextViewer";

const AuditionInfo = ({ institutionDegree, isAdmin, onChange }: { institutionDegree: InstitutionDegree, isAdmin: boolean, onChange: (institutionDegree: InstitutionDegree) => void }) => {
  const [open, setOpen] = useState(false);
  const collegeInstitutionDegree = institutionDegree;
  // const [collegeInstitutionDegree, setCollegeInstitutionDegree] = useState(institutionDegree);

  const handleSaveInstitutionDegree = (updatedInstitutionDegree: InstitutionDegree) => {
    // setCollegeInstitutionDegree({...collegeInstitutionDegree, ...updatedInstitutionDegree});
    setOpen(false);
    onChange({...collegeInstitutionDegree, ...updatedInstitutionDegree});
  }

  const action = isAdmin ? (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null;

  return (
    <Card>
      <CardHeader 
          title="Audition/Portfolio/Interview"
          action={action}
          sx={{ pb: 0 }}
        />
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Audition/Portfolio Required:</Typography>
          <Typography variant="body2">{collegeInstitutionDegree.required_audition_portfolio}</Typography>
        </Stack>

        {(collegeInstitutionDegree.required_audition_portfolio === 'Yes' || collegeInstitutionDegree.required_audition_portfolio === 'Optional') &&
          <>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">Scheduling Website:</Typography>
              <Link variant="body2" href={collegeInstitutionDegree.scheduling_url}>{institutionDegree.scheduling_url}</Link>
            </Stack>

            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">Scheduling Instructions:</Typography>
              <RichTextViewer value={collegeInstitutionDegree.scheduling_instructions} />
            </Stack>
          </>
        }

        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Audition/Portfolio Dates:</Typography>
          <RichTextViewer value={collegeInstitutionDegree.audition_dates} />
        </Stack>

        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Audition/Portfolio Requirements:</Typography>
          <RichTextViewer value={collegeInstitutionDegree.in_person_audition_requirements} />
        </Stack>

        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Unified Auditions:</Typography>
          <Typography variant="body2">{collegeInstitutionDegree.unified_auditions}</Typography>
        </Stack>
      </CardContent>

      <Dialog open={open} fullWidth>
        <DialogTitle>{`${collegeInstitutionDegree.institution?.name} ${collegeInstitutionDegree.degree?.name}`}</DialogTitle>
        <EditAudition institutionDegree={collegeInstitutionDegree} onCancel={() => setOpen(false)} onSave={handleSaveInstitutionDegree} />
      </Dialog>
    </Card>
  )
}

export default AuditionInfo