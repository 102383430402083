import { Box, Card, CardContent, Container, Dialog, Divider, Grid, Link, List, Menu, MenuItem, Stack, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link as DomLink, useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AddTask from "../tasks/AddTask";
import SecondaryButton from "../../controls/SecondaryButton";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Assessment, DocumentFile, Task, Notification, StandardizedTest } from "../../../models";
import useAuth from "../../../hooks/useAuth";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationItem from "../../notifications/NotificationItem";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ProgressItem from "../../admin/dashboard/ProgressItem";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import TaskItem from "../../tasks/TaskItem";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AddStandardizedTest from "../../standardized_tests/AddStandardizedTest";
import { TabContext, TabPanel } from "@mui/lab";

const perPage = 100;

const StudentDashboard = ({ notifications, onDismiss }: { notifications: Notification[], onDismiss: (notification: Notification) => void }) => {
  const auth = useAuth();
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [openStandardizedTest, setOpenStandardizedTest] = useState(false);
  const [value, setValue] = useState('0');

  const [meetingAnchorEl, setMeetingAnchorEl] = React.useState<null | HTMLElement>(null);
  const meetingOpen = Boolean(meetingAnchorEl);
  const handleMeetingClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMeetingAnchorEl(event.currentTarget);
  };
  const handleMeetingClose = () => {
    setMeetingAnchorEl(null);
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setDocuments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchDocuments();

    const fetchAssessments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments?per_page=${perPage}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAssessments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchAssessments();

    const fetchTasks = async () => {
      const token = auth.token();
      const url = `${window.SERVER_DATA.domain}/api/v1/tasks`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: Task[] = await response.json();
        console.log(json);
        setTasks(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchTasks();
  }, []);

  const handleSave = (newTasks: Task[]) => {
    setOpen(false);
    navigate('/tasks');
  }

  const handleMoveDocument = (document: DocumentFile) => {
    setDocuments(documents.map(d => d.id === document.id ? document : d));
  }

  const inProgressAssessments = assessments.filter(a => a.application_status === 'In Progress');
  const readyForReviewAssessments = assessments.filter(a => a.application_status === 'Ready for ArtsBridge Review');
  const reviewedAssessments = assessments.filter(a => a.application_status === 'Reviewed by ArtsBridge');
  const submittedAssessments = assessments.filter(a => a.application_status === 'Submitted');
  const withdrawnAssessments = assessments.filter(a => a.application_status === 'Withdrawn');
  const acceptedAssessments = assessments.filter(a => a.application_status === 'Accepted');
  const acceptedOnlyAcademicallyAssessments = assessments.filter(a => a.application_status === 'Accepted (Only Academically)');
  const waitlistedAssessments = assessments.filter(a => a.application_status === 'Wait Listed');
  const deniedAssessments = assessments.filter(a => a.application_status === 'Denied');
  const unselectedAssessments = assessments.filter(a => !a.application_status || a.application_status === '');

  const inProgressSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'In Progress');
  const readyForReviewSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Ready for ArtsBridge Review');
  const reviewedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Reviewed by ArtsBridge');
  const submittedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Submitted');
  const unselectedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && (!a.supplemental_application_status || a.supplemental_application_status === ''));

  const inProgressPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'In Progress');
  const readyForReviewPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Ready for ArtsBridge Review');
  const reviewedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Reviewed by ArtsBridge');
  const submittedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Submitted');
  const invitedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Invited');
  const notInvitedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Not Invited');
  const waitlistedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Wait Listed');
  const unselectedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && (!a.admission_decision || a.admission_decision === ''));


  const updateTask = (newTask: Task) => {
    setTasks(tasks.map(t => t.id === newTask.id ? newTask : t));
  }

  const handleDismiss = (notification: Notification) => {
    onDismiss(notification);
  }

  const handleSaveStandardizedTest = (newStandardizedTest: StandardizedTest) => {
    setOpenStandardizedTest(false);
    navigate('/settings/tests');
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const filteredTasks = tasks.filter(task => !task.completed_at);

  return (
    <Box>
      <Box sx={{ 
        pb: 2, 
        px: 2,
        pt: 1,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20, 
        borderBottomRightRadius: 20,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<CheckCircleOutlinedIcon/>} 
              sx={{ width: 1, lineHeight: 1, textAlign: 'left' }}
              onClick={() => setOpen(true)}
            >
              New Task/Event
            </SecondaryButton>
          </Grid>
          <Grid item xs={6} lg={3}>          
            <SecondaryButton 
              variant="outlined" 
              startIcon={<CalendarMonthOutlinedIcon/>} 
              sx={{ width: 1, lineHeight: 1, textAlign: 'left' }}
              // component={DomLink}
              // to={`/chats/${auth.user.id}`}
              onClick={handleMeetingClick}
            >
              Schedule a Meeting
            </SecondaryButton>
            <Menu
              anchorEl={meetingAnchorEl}
              open={meetingOpen}
              onClose={handleMeetingClose}
            >
              <MenuItem component={Link} href="https://meetings.hubspot.com/jessica-caccamo">Jessica Caccamo</MenuItem>
              <MenuItem component={Link} href="https://meetings.hubspot.com/matt-denis">Matt Denis</MenuItem>
              <MenuItem component={Link} href="https://meetings.hubspot.com/grace-goble">Grace Goble</MenuItem>
              <MenuItem component={Link} href="https://meetings.hubspot.com/shaun-ramsay">Shaun Ramsay</MenuItem>
              <MenuItem component={Link} href="https://meetings.hubspot.com/halley-shefler">Halley Shefler</MenuItem>
            </Menu>
          </Grid>
          <Grid item xs={6} lg={3}>          
            <SecondaryButton 
              variant="outlined" 
              startIcon={<EmailOutlinedIcon/>} 
              sx={{ width: 1, lineHeight: 1, textAlign: 'left' }}
              component={DomLink}
              to={`/chats/${auth.user.id}`}
            >
              Contact ArtsBridge
            </SecondaryButton>
          </Grid>
          <Grid item xs={6} lg={3}>          
            <SecondaryButton 
              variant="outlined" 
              startIcon={<ListAltOutlinedIcon/>} 
              sx={{ width: 1, lineHeight: 1, textAlign: 'left' }}
              onClick={() => setOpenStandardizedTest(true)}
            >
              Add Standardized Test
            </SecondaryButton>
          </Grid>
        </Grid>
      </Box>

      <Container sx={{ py: 2 }}>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={2}>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Box>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <SchoolOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>COLLEGE APPLICATIONS</Typography>
                    <Link href="/colleges?expanded=1" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <TabContext value={value}>
                    <Tabs 
                      value={value} 
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Application Status" value="0" wrapped />
                      <Tab label="Supplemental Application Status" value="1" wrapped />
                      <Tab label="Prescreen Status" value="2" wrapped  />
                    </Tabs>
                    <Divider sx={{ marginBottom: 1 }} />
                    <TabPanel value="0" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressAssessments.length} total={assessments.length} color="#F2C94C" url={`/colleges?status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewAssessments.length} total={assessments.length} color="#56CCF2" url={`/colleges?status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedAssessments.length} total={assessments.length} color="#2D9CDB" url={`/colleges?status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Application Submitted" count={submittedAssessments.length} total={assessments.length} color="#2F80ED" url={`/colleges?status=Submitted&expanded=1`} />
                      <ProgressItem title="Withdrawn" count={withdrawnAssessments.length} total={assessments.length} color="#9B51E0" url={`/colleges?status=Withdrawn&expanded=1`} />
                      <ProgressItem title="Accepted" count={acceptedAssessments.length} total={assessments.length} color="#27AE60" url={`/colleges?status=Accepted&expanded=1`} />
                      <ProgressItem title="Accepted (Only Academically)" count={acceptedOnlyAcademicallyAssessments.length} total={assessments.length} color="#6FCF97" url={`/colleges?status=Accepted (Only Academically)&expanded=1`} />
                      <ProgressItem title="Wait Listed" count={waitlistedAssessments.length} total={assessments.length} color="#F2994A" url={`/colleges?status=Wait Listed&expanded=1`} />
                      <ProgressItem title="Denied" count={deniedAssessments.length} total={assessments.length} color="#EB5757" url={`/colleges?status=Denied&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedAssessments.length} total={assessments.length} color="#333333" url={`/colleges?status=Not Selected&expanded=1`} />
                    </TabPanel>
                    <TabPanel value="1" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressSupplementalAssessments.length} total={assessments.length} color="#F2C94C" url={`/colleges?supplemental_status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewSupplementalAssessments.length} total={assessments.length} color="#56CCF2" url={`/colleges?supplemental_status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedSupplementalAssessments.length} total={assessments.length} color="#2D9CDB" url={`/colleges?supplemental_status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Submitted" count={submittedSupplementalAssessments.length} total={assessments.length} color="#2F80ED" url={`/colleges?supplemental_status=Submitted&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedSupplementalAssessments.length} total={assessments.length} color="#333333" url={`/colleges?supplemental_status=Not Selected&expanded=1`} />
                    </TabPanel>
                    <TabPanel value="2" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressPrescreenAssessments.length} total={assessments.length} color="#F2C94C" url={`/colleges?prescreen_status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewPrescreenAssessments.length} total={assessments.length} color="#56CCF2" url={`/colleges?prescreen_status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedPrescreenAssessments.length} total={assessments.length} color="#2D9CDB" url={`/colleges?prescreen_status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Submitted" count={submittedPrescreenAssessments.length} total={assessments.length} color="#2F80ED" url={`/colleges?prescreen_status=Submitted&expanded=1`} />
                      <ProgressItem title="Invited" count={invitedPrescreenAssessments.length} total={assessments.length} color="#9B51E0" url={`/colleges?prescreen_status=Invited&expanded=1`} />
                      <ProgressItem title="Not Invited" count={notInvitedPrescreenAssessments.length} total={assessments.length} color="#27AE60" url={`/colleges?prescreen_status=Not Invited&expanded=1`} />
                      <ProgressItem title="Wait Listed" count={waitlistedPrescreenAssessments.length} total={assessments.length} color="#6FCF97" url={`/colleges?prescreen_status=Wait Listed&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedPrescreenAssessments.length} total={assessments.length} color="#333333" url={`/colleges?prescreen_status=Not Selected&expanded=1`} />
                    </TabPanel>
                  </TabContext>
                </CardContent>
              </Card>
            </Box>

            <Box>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <InventoryOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>YOUR TASKS/EVENTS</Typography>
                    <Link href="/tasks" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <Divider sx={{ marginY: 2 }} />
                  {filteredTasks.map(task => (
                    <TaskItem key={task.id} task={task} onChange={updateTask} />
                  ))}
                  {filteredTasks.length === 0 && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      You're all caught up.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Stack>

          <Stack spacing={2}>
            <Box>
              <Card>
                <CardContent sx={{ p: 0 }}>
                  <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: 2 }}>
                    <NotificationsOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>NOTIFICATIONS</Typography>
                    <Link href="/notifications" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <Divider sx={{ marginY: 0 }} />
                  <List>
                    {notifications.map(notification => (
                      <Box key={notification.id}>
                        <NotificationItem notification={notification} onDismiss={handleDismiss} />
                        <Divider />
                      </Box>
                    ))}
                    {notifications.length === 0 && (
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body1" color="text.secondary">You're all caught up.</Typography>
                      </Box>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Stack>

        </Stack>
      </Container>

      <Dialog open={open} fullWidth>
        <AddTask onCancel={() => setOpen(false)} onSave={handleSave} showUsers={false} />
      </Dialog>

      <Dialog open={openStandardizedTest} fullWidth>
        <AddStandardizedTest studentId={auth.user.id} onCancel={() => setOpenStandardizedTest(false)} onSave={handleSaveStandardizedTest} />
      </Dialog>
    </Box>
  )
}

export default StudentDashboard;