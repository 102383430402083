import { IconButton, Link, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material"
import { formatDistance, parseISO } from "date-fns"
import * as React from "react"
import { useState } from "react"
import { ChatMessage, Delivery, DocumentFile, Notification, StandardizedTest, Task } from "../../models"
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import useAuth from "../../hooks/useAuth";
import { adminNotificationUrl, studentNotificationUrl } from "../../models/Notification";
import { useNavigate } from "react-router-dom";
import RichTextViewer from "../controls/RichTextViewer"

const NotificationItem = ({ notification, onDismiss }: { notification: Notification, onDismiss: (notification: Notification) => void }) => {
  const auth = useAuth();

  const deliveries = notification.deliveries?.filter(d => d.user_id === auth.user.id)
  const [delivery, setDelivery] = useState<Delivery|undefined>((deliveries?.length || 0) > 0 ? deliveries![0] : undefined);

  const isAdmin = auth.user.admin || auth.user.superadmin;

  const navigate = useNavigate();

  const dismissNotification = async (url: string|null) => {
    const token = auth.token();
    const body = {
      dismissed: true,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/notifications/${notification.id}/deliveries`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setDelivery(json);
      onDismiss(notification);
      if (url) {
        // navigate(url);
        document.location = url;
      }
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const renderIcon = () => {
    switch (notification.notification_type) {
      case 'document':
        return <DescriptionOutlinedIcon/>
      case 'task':
      case 'task_completed':
        return <InventoryOutlinedIcon/>
      case 'application_status_change':
      case 'supplemental_application_status_change':
      case 'prescreen_status_change':
      case 'applying_status_change':
        return <SchoolOutlinedIcon/>
      case 'announcement':
        return <NotificationsOutlinedIcon/>
      case 'standardized_test':
        return <ListAltOutlinedIcon/>
      case 'chat':
        return <ChatOutlinedIcon/>
      default:
        return <NotificationsOutlinedIcon/>
    }
  }

  const handleClick = (url: string) => {
    dismissNotification(url);
  }

  const renderLink = () => {
    const url = isAdmin ? adminNotificationUrl(notification) : studentNotificationUrl(notification);
    switch (notification.notification_type) {
      case 'document':
        const document = notification.object as DocumentFile|undefined;
        if (url) {
          return <Link component="button" onClick={() => handleClick(url)} align="left" variant="body2" underline="hover">Download</Link>
        }
        return <div/>
      case 'task':
      case 'task_completed':
        const task = notification.object as Task|undefined;
        if (url && task?.user) {
          return <Link component="button" onClick={() => handleClick(url)} align="left" variant="body2" underline="hover">View {task?.user.first_name} {task?.user.last_name} Tasks/Events</Link>
        }
        return <div/>
      case 'application_status_change':
      case 'supplemental_application_status_change':
      case 'prescreen_status_change':
      case 'applying_status_change':
        if (url) {
          return <Link component="button" onClick={() => handleClick(url)} align="left" variant="body2" underline="hover">View Application</Link>
        }
        return <div/>
      case 'announcement':
        return <div/>
      case 'standardized_test':
        const test = notification.object as StandardizedTest|undefined;
        if (url && test?.user) {
          return <Link component="button" onClick={() => handleClick(url)} align="left" variant="body2" underline="hover">View {test?.user?.first_name} {test?.user?.last_name} Standardized Test Scores</Link>
        }
      case 'chat':
        const message = notification.object as ChatMessage|undefined;
        if (url) {
          return <Link component="button" onClick={() => handleClick(url)} align="left" variant="body2" underline="hover">View Message</Link>
        }
      default:
        return <div/>
    }
  }

  return (
    <ListItem>
      <ListItemIcon sx={{ flexShrink: 1, minWidth: 38 }}>
        {renderIcon()}
      </ListItemIcon>
      <ListItemText>
        <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
          <Stack sx={{ flexGrow: 1 }}>
            <Typography variant="body2" color="text.secondary">{formatDistance(parseISO(notification.created_at), new Date())} ago</Typography>
            <Typography variant="subtitle1">{notification.title}</Typography>
            <Typography variant="body2">{notification.message}</Typography>
            <RichTextViewer value={notification.message} />
            {renderLink()}
          </Stack>
          <IconButton sx={{ flexGrow: 0, p: 0 }} onClick={() => dismissNotification(null)}>
            <ClearOutlinedIcon />
          </IconButton>
        </Stack>
      </ListItemText>
    </ListItem>
  )
}

export default NotificationItem;