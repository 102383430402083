import { Box, Card, CardContent, Container, Dialog, Divider, Grid, Link, List, Stack, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AdminAddTask from "../tasks/AdminAddTask";
import SecondaryButton from "../../controls/SecondaryButton";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Assessment, DocumentFile, Task, User, Notification, ChatMessage } from "../../../models";
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ProgressItem from "./ProgressItem";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import TaskItem from "../../tasks/TaskItem";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import NotificationItem from "../../notifications/NotificationItem";
import ChatDialog from "../../messages/ChatDialog";
import useAuth from "../../../hooks/useAuth";
import NewAnnouncement from "../../announcements/NewAnnouncement";
import { TabContext, TabPanel } from "@mui/lab";

const perPage = 20;

const AdminDashboard = ({ notifications, onDismiss }: { notifications: Notification[], onDismiss: (notification: Notification) => void }) => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [students, setStudents] = useState<User[]>([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const [chatOpen, setChatOpen] = useState(false);
  const [announcementsOpen, setAnnouncementsOpen] = useState(false);
  const [value, setValue] = useState('0');

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const fetchAssessments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments?seniors=1&page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: Assessment[] = await response.json();
        if (page === 1) {
          setAssessments(json);
        } else {
          setAssessments(prev => {
            const filteredPrev = prev.filter(a => {
              return !json.map(b => b.id).includes(a.id);
            });
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        } else {
          loadNextPage();
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchAssessments();

    const fetchTasks = async () => {
      const token = auth.token();
      const url = `${window.SERVER_DATA.domain}/api/v1/tasks`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: Task[] = await response.json();
        setTasks(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchTasks();

    const fetchDocuments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        setDocuments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchDocuments();

    const fetchStudents = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        setStudents(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchStudents();

  }, [page]);

  const handleSave = (newTasks: Task[]) => {
    setOpen(false);
    navigate('/tasks');
  }

  const handleDismiss = (notification: Notification) => {
    onDismiss(notification);
  }

  const inProgressAssessments = assessments.filter(a => a.application_status === 'In Progress');
  const readyForReviewAssessments = assessments.filter(a => a.application_status === 'Ready for ArtsBridge Review');
  const reviewedAssessments = assessments.filter(a => a.application_status === 'Reviewed by ArtsBridge');
  const submittedAssessments = assessments.filter(a => a.application_status === 'Submitted');
  const withdrawnAssessments = assessments.filter(a => a.application_status === 'Withdrawn');
  const acceptedAssessments = assessments.filter(a => a.application_status === 'Accepted');
  const acceptedOnlyAcademicallyAssessments = assessments.filter(a => a.application_status === 'Accepted (Only Academically)');
  const waitlistedAssessments = assessments.filter(a => a.application_status === 'Wait Listed');
  const deniedAssessments = assessments.filter(a => a.application_status === 'Denied');
  const unselectedAssessments = assessments.filter(a => !a.application_status || a.application_status === '');

  const inProgressSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'In Progress');
  const readyForReviewSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Ready for ArtsBridge Review');
  const reviewedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Reviewed by ArtsBridge');
  const submittedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && a.supplemental_application_status === 'Submitted');
  const unselectedSupplementalAssessments = assessments.filter(a => a.institution_degree?.supplemental_application_required === 'Yes' && a.institution_degree?.supplemental_application_url && (!a.supplemental_application_status || a.supplemental_application_status === ''));

  const inProgressPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'In Progress');
  const readyForReviewPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Ready for ArtsBridge Review');
  const reviewedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Reviewed by ArtsBridge');
  const submittedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Submitted');
  const invitedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Invited');
  const notInvitedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Not Invited');
  const waitlistedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && a.admission_decision === 'Wait Listed');
  const unselectedPrescreenAssessments = assessments.filter(a => a.institution_degree?.pre_screening === 'Yes' && (!a.admission_decision || a.admission_decision === ''));

  const updateTask = (newTask: Task) => {
    setTasks(tasks.map(t => t.id === newTask.id ? newTask : t));
  }

  const sendMessage = async (chatMessage: ChatMessage) => {
    const token = auth.token();
    const body = {
      body: chatMessage.body,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/chats/${chatMessage.chat_id}/chat_messages`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      navigate(`/chats/${chatMessage.chat_id}`)
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const filteredTasks = tasks.filter(task => !task.completed_at);

  return (
    <Box>
      <Box sx={{ 
        pb: 2, 
        px: 2,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20, 
        borderBottomRightRadius: 20,
      }}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<CheckCircleOutlinedIcon/>} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setOpen(true)}
            >
              New Task/Event
            </SecondaryButton>
          </Grid>
          <Grid item xs={6} lg={3}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<EmailOutlinedIcon/>} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setChatOpen(true)}
            >
              Contact Students
            </SecondaryButton>
          </Grid> 
          <Grid item xs={6} lg={3}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<NotificationsOutlinedIcon />} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setAnnouncementsOpen(true)}
            >
              Create Announcement
            </SecondaryButton>
          </Grid>
        </Grid>
      </Box>

      <Container sx={{ py: 2 }}>
        <Stack direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={2}>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Box>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <SchoolOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>COLLEGE APPLICATIONS</Typography>
                    <Link href="/students?expanded=1" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <TabContext value={value}>
                    <Tabs 
                      value={value} 
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="Application Status" value="0" wrapped />
                      <Tab label="Supplemental Application Status" value="1" wrapped />
                      <Tab label="Prescreen Status" value="2" wrapped  />
                    </Tabs>
                    <Divider sx={{ marginBottom: 1 }} />
                    <TabPanel value="0" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressAssessments.length} total={assessments.length} color="#F2C94C" url={`/students?status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewAssessments.length} total={assessments.length} color="#56CCF2" url={`/students?status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedAssessments.length} total={assessments.length} color="#2D9CDB" url={`/students?status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Application Submitted" count={submittedAssessments.length} total={assessments.length} color="#2F80ED" url={`/students?status=Submitted&expanded=1`} />
                      <ProgressItem title="Withdrawn" count={withdrawnAssessments.length} total={assessments.length} color="#9B51E0" url={`/students?status=Withdrawn&expanded=1`} />
                      <ProgressItem title="Accepted" count={acceptedAssessments.length} total={assessments.length} color="#27AE60" url={`/students?status=Accepted&expanded=1`} />
                      <ProgressItem title="Accepted (Only Academically)" count={acceptedOnlyAcademicallyAssessments.length} total={assessments.length} color="#6FCF97" url={`/students?status=Accepted (Only Academically)&expanded=1`} />
                      <ProgressItem title="Wait Listed" count={waitlistedAssessments.length} total={assessments.length} color="#F2994A" url={`/students?status=Wait Listed&expanded=1`} />
                      <ProgressItem title="Denied" count={deniedAssessments.length} total={assessments.length} color="#EB5757" url={`/students?status=Denied&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedAssessments.length} total={assessments.length} color="#333333" url={`/students?status=Not Selected&expanded=1`} />
                    </TabPanel>
                    <TabPanel value="1" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressSupplementalAssessments.length} total={assessments.length} color="#F2C94C" url={`/students?supplemental_status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewSupplementalAssessments.length} total={assessments.length} color="#56CCF2" url={`/students?supplemental_status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedSupplementalAssessments.length} total={assessments.length} color="#2D9CDB" url={`/students?supplemental_status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Submitted" count={submittedSupplementalAssessments.length} total={assessments.length} color="#2F80ED" url={`/students?supplemental_status=Submitted&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedSupplementalAssessments.length} total={assessments.length} color="#333333" url={`/students?supplemental_status=Not Selected&expanded=1`} />
                    </TabPanel>
                    <TabPanel value="2" sx={{p: 0}}>
                      <ProgressItem title="In Progress" count={inProgressPrescreenAssessments.length} total={assessments.length} color="#F2C94C" url={`/students?prescreen_status=In Progress&expanded=1`} />
                      <ProgressItem title="Ready for ArtsBridge Review" count={readyForReviewPrescreenAssessments.length} total={assessments.length} color="#56CCF2" url={`/students?prescreen_status=Ready for ArtsBridge Review&expanded=1`} />
                      <ProgressItem title="Reviewed by ArtsBridge" count={reviewedPrescreenAssessments.length} total={assessments.length} color="#2D9CDB" url={`/students?prescreen_status=Reviewed by ArtsBridge&expanded=1`} />
                      <ProgressItem title="Submitted" count={submittedPrescreenAssessments.length} total={assessments.length} color="#2F80ED" url={`/students?prescreen_status=Submitted&expanded=1`} />
                      <ProgressItem title="Invited" count={invitedPrescreenAssessments.length} total={assessments.length} color="#9B51E0" url={`/students?prescreen_status=Invited&expanded=1`} />
                      <ProgressItem title="Not Invited" count={notInvitedPrescreenAssessments.length} total={assessments.length} color="#27AE60" url={`/students?prescreen_status=Not Invited&expanded=1`} />
                      <ProgressItem title="Wait Listed" count={waitlistedPrescreenAssessments.length} total={assessments.length} color="#6FCF97" url={`/students?prescreen_status=Wait Listed&expanded=1`} />
                      <ProgressItem title="Unselected" count={unselectedPrescreenAssessments.length} total={assessments.length} color="#333333" url={`/students?prescreen_status=Not Selected&expanded=1`} />
                    </TabPanel>
                  </TabContext>
                </CardContent>
              </Card>
            </Box>

            <Box>
              <Card>
                <CardContent>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <InventoryOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>YOUR TASKS/EVENTS</Typography>
                    <Link href="/tasks" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <Divider sx={{ marginY: 2 }} />
                  {filteredTasks.map(task => (
                    <TaskItem key={task.id} task={task} onChange={updateTask} />
                  ))}
                  {filteredTasks.length === 0 && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      You're all caught up.
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Box>
          </Stack>

          <Stack spacing={2}>
            <Box>
              <Card>
                <CardContent sx={{ p: 0 }}>
                  <Stack direction="row" spacing={2} alignItems="center" sx={{ padding: 2 }}>
                    <NotificationsOutlinedIcon />
                    <Typography variant="h6" sx={{flexGrow: 1}}>NOTIFICATIONS</Typography>
                    <Link href="/notifications" underline="hover" variant="subtitle1">See All</Link>
                  </Stack>
                  <Divider sx={{ marginY: 0 }} />
                  <List>
                    {notifications.slice(0, 10).map(notification => (
                      <Box key={notification.id}>
                        <NotificationItem notification={notification} onDismiss={handleDismiss} />
                        <Divider />
                      </Box>
                    ))}
                    {notifications.length === 0 && (
                      <Box sx={{ p: 2 }}>
                        <Typography variant="body1" color="text.secondary">You're all caught up.</Typography>
                      </Box>
                    )}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Stack>

        </Stack>
      </Container>

      <Dialog open={open} fullWidth>
        <AdminAddTask onCancel={() => setOpen(false)} onSave={handleSave} showUsers={true} />
      </Dialog>

      <Dialog open={chatOpen} fullWidth>
        <ChatDialog onCancel={() => setChatOpen(false)} onSend={sendMessage} />
      </Dialog>

      <Dialog open={announcementsOpen} fullWidth>
        <NewAnnouncement onCancel={() => setAnnouncementsOpen(false)} onSave={() => setAnnouncementsOpen(false)} />
      </Dialog>
    </Box>
  )
}

export default AdminDashboard;