import DocumentFile from "./DocumentFile";
import Folder from "./Folder";
import InstitutionDegree from "./InstitutionDegree";
import Task from "./Task";
import User from "./User";

export default interface Assessment {
  id?: number;

  institution_degree_id: number;
  institution_degree?: InstitutionDegree;

  user_id?: number;
  user?: User;

  tasks?: Task[];
  documents?: DocumentFile[];
  folders?: Folder[];

  academic_confidence: string;
  artistic_confidence: string;
  scholarship: string;
  application_status: string;
  applying: string;
  prescreen_deadline: string;
  prescreen_artistic_upload_status: string;
  supplemental_application_status: string;
  inactive: boolean;
  inactive_explanation: string;
  notes: string;
  admission_decision: string;

  test_optional?: string;
  test_optional_other?: string;

  updated_at?: string;

  calc_application_deadline?: string;
  calc_prescreen_deadline?: string;
}

export const applicationStatusOptions = (user?: User) => {
  const options = [["In Progress", "In Progress"]];
  if (!user?.gap_year) {
    options.push(
      ["Ready for ArtsBridge Review", "Ready for ArtsBridge Review"], 
      ["Reviewed by ArtsBridge", "Reviewed by ArtsBridge"],
    );
  }
  options.push(
    ["Application Submitted", "Submitted"],
    ["Withdrawn", "Withdrawn"],
    ["Accepted", "Accepted"],
    ["Accepted (Only Academically)", "Accepted (Only Academically)"],
    ["Wait Listed", "Wait Listed"],
    ["Denied", "Denied"],
  );
  return options;
}

export const applyingOptions = (institution_degree?: InstitutionDegree) => {
  const options: string[][] = [];
  if (institution_degree?.early_action === "Yes") {
    options.push(["Early Action", "Early Action"]);
  }
  if (institution_degree?.early_decision === "Yes") {
    options.push(["Early Decision", "Early Decision"]);
  }
  options.push(
    ["Regular Decision", "Regular Decision"],
    ["Other Decision", "Other Decision"],
  );
  return options;
}

export const allApplyingOptions = [
  ["Early Action", "Early Action"],
  ["Early Decision", "Early Decision"],
  ["Regular Decision", "Regular Decision"],
  ["Other Decision", "Other Decision"],
];

export const admissionDecisionOptions = [
  ['In Progress'], 
  ['Ready for ArtsBridge Review'], 
  ['Reviewed by ArtsBridge'], 
  ["Submitted", "Submitted"],
  ["Invited", "Invited"],
  ["Not Invited", "Not Invited"],
  ["Wait Listed", "Wait Listed"]
];

export const statusOptions = [
  ['In Progress'], 
  ['Ready for ArtsBridge Review'], 
  ['Reviewed by ArtsBridge'], 
  ['Submitted']
];

export const testOptionalOptions = [
  ['Please Select', ''],
  ['Submit'],
  ["Don't Submit"],
  ['Other']
]