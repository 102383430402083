import Assessment from "./Assessment";
import ChatMessage from "./ChatMessage";
import Delivery from "./Delivery";
import DocumentFile from "./DocumentFile";
import StandardizedTest from "./StandardizedTest";
import Task from "./Task";
import User from "./User";

export default interface Notification {
  id?: number;
  notification_type: string;
  title: string;
  message: string;
  object_type: string;
  object_id: number;
  user_id: number;
  created_at: string;

  object?: any;

  deliveries?: Delivery[];
  user?: User;
}

const adminNotificationUrl = (notification: Notification): string|undefined => {
  switch (notification.notification_type) {
    case 'document':
      const document = notification.object as DocumentFile|undefined;
      return document?.attachment?.url;
    case 'task':
    case 'task_completed':
      const task = notification.object as Task|undefined;
      return `/students/${task?.user_id}/tasks`;
    case 'application_status_change':
    case 'supplemental_application_status_change':
    case 'prescreen_status_change':
    case 'applying_status_change':
      const assessment = notification.object as Assessment|undefined;
      return `/assessments/${assessment?.id}`;
    case 'announcement':
      return '/notifications';
    case 'standardized_test':
      const test = notification.object as StandardizedTest|undefined;
      return `/students/${test?.user_id}/tests`;
    case 'chat':
      const message = notification.object as ChatMessage|undefined;
      return `/chats/${message?.user_id}`;
    default:
      return;
  }
}

const studentNotificationUrl = (notification: Notification): string|undefined => {
  switch (notification.notification_type) {
    case 'document':
      const document = notification.object as DocumentFile|undefined;
      return document?.attachment?.url;
    case 'task':
    case 'task_completed':
      const task = notification.object as Task|undefined;
      return `/tasks`;
    case 'application_status_change':
    case 'supplemental_application_status_change':
    case 'prescreen_status_change':
    case 'applying_status_change':
      const assessment = notification.object as Assessment|undefined;
      return `/assessments/${assessment?.id}`;
    case 'announcement':
      return '/notifications';
    case 'standardized_test':
      const test = notification.object as StandardizedTest|undefined;
      return `/settings/tests`;
    case 'chat':
      const message = notification.object as ChatMessage|undefined;
      return `/chats/${message?.user_id}`;
    default:
      return;
  }
}

export { adminNotificationUrl, studentNotificationUrl }