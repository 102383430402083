import { Avatar, Box, Card, CardContent, CardHeader, Container, Grid, Link, Stack, Typography } from "@mui/material";
import * as React from "react";
import EditableRichNotes from "../../../controls/EditableRichNotes";
import { User } from "../../../../models";
import RichTextViewer from "../../../controls/RichTextViewer";
import { format, parseISO } from "date-fns";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

const StudentInfo = ({ student, onChange }: { student: User, onChange: (student: User) => void }) => {

  const isValid = isValidPhoneNumber(student.phone || '');
  console.log('isValid', isValid)
  const phoneNumber = isValid ? parsePhoneNumber(student.phone || '') : null;
  console.log('phoneNumber', phoneNumber)
  const parsedPhoneNumber = phoneNumber ? phoneNumber.formatInternational() : student.phone
  console.log('parsedPhoneNumber', parsedPhoneNumber)

  const gridContainer = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)"
  };

  const gridItem = {
    '&:nth-child(odd)': {
      marginRight: '16px'
    }
  };

  return (
    <Container sx={{py: 2}}>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>
              {student.biography &&
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Biography</Typography>
                  <RichTextViewer value={student.biography} />
                </Grid>
              }
              
              {student.email &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Email</Typography>
                  <Typography variant="body2">{student.email}</Typography>
                </Grid>
              }

              {parsedPhoneNumber &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Phone Number</Typography>
                  <Typography variant="body2">{parsedPhoneNumber}</Typography>
                </Grid>
              }
              
              {false &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Address</Typography>
                  <Typography variant="body2"></Typography>
                </Grid>
              }

              {student.high_school_graduation_year &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Year of Intended Enrollment</Typography>
                  <Typography variant="body2">{student.high_school_graduation_year}</Typography>
                </Grid>
              }

              {student.actual_graduation_year &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">High School Graduation Year</Typography>
                  <Typography variant="body2">{student.actual_graduation_year}</Typography>
                </Grid>
              }

              {false &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">ArtsBridge Programs</Typography>
                  <Typography variant="body2"></Typography>
                </Grid>
              }

              {student.paid_student &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">College Client</Typography>
                  <Typography variant="body2">{student.paid_student ? 'Yes' : 'No'}</Typography>
                </Grid>
              }

              {student.gap_year &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Gap Year</Typography>
                  <Typography variant="body2">{student.gap_year ? 'Yes' : 'No'}</Typography>
                </Grid>
              }

              {student.high_school &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">High School</Typography>
                  <Typography variant="body2">{student.high_school}</Typography>
                </Grid>
              }

              {false &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Parents' Names</Typography>
                  <Typography variant="body2"></Typography>
                </Grid>
              }

              {student.intended_college_major &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Intended College Major</Typography>
                  <Typography variant="body2">{student.intended_college_major}</Typography>
                </Grid>
              }

              {student.artsbridge_programs_attended &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">ArtsBridge Programs</Typography>
                  <Typography variant="body2">{student.artsbridge_programs_attended}</Typography>
                </Grid>
              }

              {student.consultant &&
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Consultant</Typography>
                  <Stack direction="row">
                    <CardHeader
                      avatar={<Avatar alt={`${student.consultant.first_name} ${student.consultant.last_name}`} src={student.consultant.avatar?.url} />}
                      title={`${student.consultant.first_name} ${student.consultant.last_name}`}
                      subheader={student.consultant.email}
                      sx={{ backgroundColor: 'background.default' }}
                    />
                  </Stack>
                </Grid>
              }

              {false &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Last Meeting</Typography>
                  <Typography variant="body2"></Typography>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>

        {student.transfer_student &&
          <>
            <Card>
              <CardContent>
                <Typography variant="subtitle2">Transfer Student</Typography>
                
                <Grid container rowSpacing={1} columnSpacing={1}>

                  {student.transfer_deadline &&
                    <Grid item xs={6}>
                      <Typography variant="subtitle2">Early Action Date:</Typography>
                      <Typography variant="body2">{format(parseISO(student.transfer_deadline), 'MMM d, yyyy')}</Typography>
                    </Grid>
                  }


                  <Grid item xs={6}>
                    <Typography variant="subtitle2">Transfer Website:</Typography>
                    <Typography variant="body2"><Link href={student.transfer_website} target="_blank">{student.transfer_website}</Link></Typography>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography variant="subtitle2">Transfer Notes</Typography>
                <EditableRichNotes 
                  endpoint={`students/${student.id}`} 
                  field="transfer_notes" 
                  value={student.transfer_notes || ''} 
                  onChange={(value) => { onChange({ ...student, transfer_notes: value }) }}
                />
              </CardContent>
            </Card>
          </>
        }
        
        <Box sx={gridContainer}>
          <Box sx={gridItem}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="subtitle2">Confidential ArtsBridge Notes</Typography>
                <EditableRichNotes 
                  sx={{ height: '100%' }} 
                  endpoint={`students/${student.id}`} 
                  field="notes_from" 
                  value={student.notes_from || ''} 
                  onChange={(value) => { onChange({ ...student, notes_from: value }) }}
                />
              </CardContent>
            </Card>
          </Box>

          <Box sx={gridItem}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="subtitle2">Meeting Notes</Typography>
                <EditableRichNotes 
                  sx={{ height: '100%' }} 
                  endpoint={`students/${student.id}`} 
                  field="meeting_notes" 
                  value={student.meeting_notes || ''} 
                  onChange={(value) => { onChange({ ...student, meeting_notes: value }) }}
                />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Stack>
    </Container>
  )
}

export default StudentInfo;