import { Box, Card, CardContent, Chip, Divider, Grid, Stack, Tab, TextField, Typography } from "@mui/material";
import * as React from "react";
import { TabContext, TabPanel } from "@mui/lab";
import CollegeInfo from "./college/CollegeInfo";
import { SyntheticEvent, useState } from "react";
import DegreeInfo from "./degree/DegreeInfo";
import PrescreenInfo from "./prescreen/PrescreenInfo";
import AuditionInfo from "./audition/AuditionInfo";
import StatusList from "../../../../controls/StatusList/StatusList";
import ConfidenceList from "../../../../controls/StatusList/ConfidenceList";
import useRouteMatch from "../../../../../hooks/useRouteMatch";
import { Link } from "react-router-dom";
import RoundedTopTabs from "../../../../controls/RoundedTopTabs";
import { Assessment, InstitutionDegree } from "../../../../../models";
import EditableRichNotes from "../../../../controls/EditableRichNotes";
import RichTextViewer from "../../../../controls/RichTextViewer";
import StatusListItem from "../../../../controls/StatusList/StatusListItem";
import { testOptionalOptions } from "../../../../../models/Assessment";
import useAuth from "../../../../../hooks/useAuth";

const AssessmentOverview = ({ assessment, isAdmin, onChange }: { assessment: Assessment, isAdmin: boolean, onChange: (assessment: Assessment) => void }) => {
  const routeMatch = useRouteMatch([`/assessments/${assessment.id}`, `/assessments/${assessment.id}/degree`, `/assessments/${assessment.id}/prescreen`, `/assessments/${assessment.id}/audition`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/assessments/${assessment.id}`);

  const [testOptionalOther, setTestOptionalOther] = useState(assessment.test_optional_other || '')
  const auth = useAuth();
  
  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleInstitutionDegreeChange = (institutionDegree: InstitutionDegree) => {
    onChange({ ...assessment, institution_degree: institutionDegree });
  }

  const handleTestOptionalRecommendationChange = (value: string) => {
    onChange({ ...assessment, test_optional: value });
  }

  const debounceTestOptionalOtherChange = (value: string) => {
    setTestOptionalOther(value);
    handleTestOptionalOtherChange(value);
  }
  
  const handleTestOptionalOtherChange = async (value: string) => {
    const token = auth.token();
    const body = {
      test_optional_other: value
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments/${assessment.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onChange({ ...assessment, test_optional_other: value });
    } else {
      const json = await response.json();
      console.error(json);
    }
  }
  return (
    <Stack spacing={2}>
      <Card>
        <CardContent>
          <Stack spacing={1}>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Year of Intended Enrollment:</Typography>
                  <Typography variant="body2">{assessment.user?.high_school_graduation_year}</Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">High School Graduation Year:</Typography>
                  <Typography variant="body2">{assessment.user?.actual_graduation_year}</Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Scholarship:</Typography>
                  <Typography variant="body2">{assessment.scholarship}</Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Score Optional:</Typography>
                  <Typography variant="body2">{assessment.institution_degree?.score_optional_enabled}</Typography>
                </Stack>

                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Score Optional Policy:</Typography>
                  <RichTextViewer value={assessment.institution_degree?.score_optional || ''} />
                </Stack>

                {assessment.institution_degree?.score_optional_enabled === 'Yes' &&
                  <Stack direction="column" spacing={2}>
                    <StatusListItem id={assessment.id!} field="test_optional" label="Test Optional Recommendation:" value={assessment.test_optional || ''} options={testOptionalOptions} onChange={handleTestOptionalRecommendationChange} isEditable={isAdmin} />  
                    {assessment.test_optional === 'Other' && 
                      <>
                        {isAdmin ? (
                          <TextField
                            label="Other Test Optional Recommendation"
                            variant="outlined"
                            value={testOptionalOther}
                            onChange={(e) => debounceTestOptionalOtherChange(e.target.value)} />
                        ) : (
                          <Typography variant="body2">{testOptionalOther}</Typography>
                        )}
                      </>
                    }
                  </Stack>
                }
                
              </Grid>

              <Grid item xs={6} sx={{ textAlign: 'right' }}>
                {assessment.inactive &&
                  <>
                    <Chip label="Inactive" color="error" size="small" sx={{ mb: 1 }} />
                    <Typography variant="body2">{assessment.inactive_explanation}</Typography>
                  </>
                }
              </Grid>
            </Grid>

            <StatusList assessment={assessment} onChange={() => {}} />
            <Divider/>
            <ConfidenceList assessment={assessment} isAdmin={isAdmin} onChange={() => {}} />
          </Stack>
        </CardContent>
      </Card>

      <Box>
        <TabContext value={value}>
          <RoundedTopTabs 
            value={value} 
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="College" value={`/assessments/${assessment.id}`} component={Link} to={`/assessments/${assessment.id}`} />
            <Tab label="Degree" value={`/assessments/${assessment.id}/degree`} component={Link} to={`/assessments/${assessment.id}/degree`} />
            {assessment.institution_degree?.pre_screening === 'Yes' &&
              <Tab label="Prescreen" value={`/assessments/${assessment.id}/prescreen`} component={Link} to={`/assessments/${assessment.id}/prescreen`} />
            }
            {(assessment.institution_degree?.required_audition_portfolio === 'Yes' || assessment.institution_degree?.required_audition_portfolio === 'Optional') &&
              <Tab label="Audition/Portfolio/Interview" value={`/assessments/${assessment.id}/audition`} component={Link} to={`/assessments/${assessment.id}/audition`} />
            }
          </RoundedTopTabs>
          <TabPanel value={`/assessments/${assessment.id}`} sx={{p: 0}}>
            {assessment.institution_degree?.institution &&
              <CollegeInfo assessment={assessment} isAdmin={isAdmin} onChange={onChange} />
            }
          </TabPanel>
          <TabPanel value={`/assessments/${assessment.id}/degree`} sx={{p: 0}}>
            {assessment.institution_degree &&
              <DegreeInfo institutionDegree={assessment.institution_degree} isAdmin={isAdmin} onChange={handleInstitutionDegreeChange} />
            }
          </TabPanel>
          <TabPanel value={`/assessments/${assessment.id}/prescreen`} sx={{p: 0}}>
            {assessment.institution_degree &&
              <PrescreenInfo assessment={assessment} isAdmin={isAdmin} onChange={handleInstitutionDegreeChange} />
            }
          </TabPanel>
          <TabPanel value={`/assessments/${assessment.id}/audition`} sx={{p: 0}}>
            {assessment.institution_degree &&
              <AuditionInfo institutionDegree={assessment.institution_degree} isAdmin={isAdmin} onChange={handleInstitutionDegreeChange} />
            }
          </TabPanel>
        </TabContext>
      </Box>

      <Card>
        <CardContent>
          <Typography variant="subtitle2">Notes</Typography>
          <EditableRichNotes 
            endpoint={`assessments/${assessment.id}`} 
            field="notes" 
            value={assessment.notes || ''} 
            wrap="assessment" 
            onChange={(value) => { onChange({ ...assessment, notes: value }) }}
          />
          <Typography variant="caption">Use this for any notes that you would like to keep about this college.</Typography>
        </CardContent>
      </Card>

    </Stack>
  )
}

export default AssessmentOverview;