import * as React from "react";
import StatusListItem from "./StatusListItem";
import './StatusList.css';
import { Assessment } from "../../../models";
import { applicationStatusOptions, applyingOptions, admissionDecisionOptions, statusOptions } from "../../../models/Assessment";

const StatusList = ({ assessment, onChange }: { assessment: Assessment, onChange: (assessment: Assessment) => void }) => {

  if (!assessment.id) return null;

  return (
    <ul className="status-list">
      <li><StatusListItem id={assessment.id} field="applying" label="Applying:" value={assessment.applying} options={applyingOptions(assessment.institution_degree)} onChange={(applying) => onChange({...assessment, applying})} /></li>
      <li><StatusListItem id={assessment.id} field="application_status" label="Application Status:" value={assessment.application_status} options={applicationStatusOptions(assessment.user)} onChange={(application_status) => onChange({...assessment, application_status})} /></li>
      {assessment.institution_degree?.supplemental_application_required === 'Yes' && assessment.institution_degree?.supplemental_application_url &&
        <li><StatusListItem id={assessment.id} field="supplemental_application_status" label="Supplemental Application Status:" value={assessment.supplemental_application_status} options={statusOptions} onChange={(supplemental_application_status) => onChange({...assessment, supplemental_application_status})} /></li>
      }
      {assessment.institution_degree?.pre_screening === 'Yes' &&
        <li><StatusListItem id={assessment.id} field="admission_decision" label="Prescreen Status:" value={assessment.admission_decision} options={admissionDecisionOptions} onChange={(admission_decision) => onChange({...assessment, admission_decision})} /></li>
      }
    </ul>
  )
}

export default StatusList;