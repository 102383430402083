import { Card, CardContent, Container, Grid, Link, Stack, Typography } from "@mui/material";
import { format, parse } from "date-fns";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import * as React from "react";
import { InstitutionDegree } from "../../../../models";
import RichTextViewer from "../../../controls/RichTextViewer";

const formatDate = (dateString: string) => {
  if (dateString) {
    const date = parse(dateString, "yyyy-MM-dd", new Date());
    return format(date, "MMM d, yyyy");
  }
  return '';
}
const InstitutionDegreeInfo = ({ institutionDegree }: { institutionDegree: InstitutionDegree }) => {

  const isValid = isValidPhoneNumber(institutionDegree.admissions_office_phone || '');
  const phoneNumber = isValid ? parsePhoneNumber(institutionDegree.admissions_office_phone || '') : null;
  const parsedPhoneNumber = phoneNumber ? phoneNumber.formatInternational() : institutionDegree.admissions_office_phone

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Degree Info</Typography>
              </Grid>

              {institutionDegree.website_url &&
                <Grid item xs={12}>
                  <Typography variant="subtitle2">Degree Program Description Website</Typography>
                  <Link variant="body2" href={institutionDegree.website_url}>{institutionDegree.website_url}</Link>
                </Grid>
              }

              {parsedPhoneNumber &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Admissions Office Phone</Typography>
                  <Typography variant="body2">{parsedPhoneNumber}</Typography>
                </Grid>
              }

              {institutionDegree.admissions_office_email &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Admissions Office Email</Typography>
                  <Typography variant="body2">{institutionDegree.admissions_office_email}</Typography>
                </Grid>
              }

              {institutionDegree.score_optional_enabled &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Score Optional</Typography>
                  <Typography variant="body2">{institutionDegree.score_optional_enabled}</Typography>
                </Grid>
              }

              {institutionDegree.score_optional &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Score Optional Policy</Typography>
                  <RichTextViewer value={institutionDegree.score_optional} />
                </Grid>
              }

              {institutionDegree.comments_about_degree &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Comments About The Degree</Typography>
                  <RichTextViewer value={institutionDegree.comments_about_degree} />
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Supplemental Application</Typography>
              </Grid>

              {institutionDegree.supplemental_application_required &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Supplemental Application Required</Typography>
                  <Typography variant="body2">{institutionDegree.supplemental_application_required}</Typography>
                </Grid>
              }

              {institutionDegree.supplemental_application_required === 'Yes' && institutionDegree.supplemental_application_deadline &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Supplemental Application Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.supplemental_application_deadline)}</Typography>
                </Grid>
              }

              {institutionDegree.supplemental_application_required === 'Yes' && institutionDegree.supplemental_application_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Supplemental Application Website</Typography>
                  <Typography variant="body2">{institutionDegree.supplemental_application_url}</Typography>
                </Grid>
              }

              {institutionDegree.supplemental_application_required === 'Yes' && institutionDegree.supplemental_application_requirements &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Supplemental Application Reqs</Typography>
                  <RichTextViewer value={institutionDegree.supplemental_application_requirements} />
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Prescreen</Typography>
              </Grid>

              {institutionDegree.pre_screening &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Prescreen Required</Typography>
                  <Typography variant="body2">{institutionDegree.pre_screening}</Typography>
                </Grid>
              }

              {/*institutionDegree.multiple_prescreens &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Multiple Prescreens</Typography>
                  <Typography variant="body2">{institutionDegree.multiple_prescreens}</Typography>
                </Grid>
              */}

              {institutionDegree.pre_screening_requirements_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Prescreen Requirements</Typography>
                  <RichTextViewer value={institutionDegree.pre_screening_requirements_url} />
                </Grid>
              }

              {institutionDegree.prescreen_artistic_upload_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Prescreen Artistic Upload Website</Typography>
                  <Typography variant="body2">{institutionDegree.prescreen_artistic_upload_url}</Typography>
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Audition/Portfolio/Interview</Typography>
              </Grid>

              {institutionDegree.required_audition_portfolio &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Audition/Portfolio Required</Typography>
                  <Typography variant="body2">{institutionDegree.required_audition_portfolio}</Typography>
                </Grid>
              }

              {institutionDegree.scheduling_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Scheduling Website</Typography>
                  <Typography variant="body2">{institutionDegree.scheduling_url}</Typography>
                </Grid>
              }

              {institutionDegree.scheduling_instructions &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Scheduling Instructions</Typography>
                  <RichTextViewer value={institutionDegree.scheduling_instructions} />
                </Grid>
              }

              {institutionDegree.unified_auditions &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Unified Auditions</Typography>
                  <Typography variant="body2">{institutionDegree.unified_auditions}</Typography>
                </Grid>
              }

              {institutionDegree.audition_dates &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Audition/Portfolio Dates</Typography>
                  <RichTextViewer value={institutionDegree.audition_dates} />
                </Grid>
              }

              {institutionDegree.in_person_audition_requirements &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Audition/Portfolio Requirements</Typography>
                  <RichTextViewer value={institutionDegree.in_person_audition_requirements} />
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Department Visit Information</Typography>
              </Grid>

              {institutionDegree.department_visit_information &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Department Visit Information</Typography>
                  <RichTextViewer value={institutionDegree.department_visit_information} />
                </Grid>
              }
        
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Regular Admission</Typography>
              </Grid>

              {institutionDegree.regular_deadline &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Regular Admission Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.regular_deadline)}</Typography>
                </Grid>
              }

              {institutionDegree.pre_screening_deadline &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Regular Decision Prescreen Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.pre_screening_deadline)}</Typography>
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Early Action</Typography>
              </Grid>

              {institutionDegree.early_action &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Action</Typography>
                  <Typography variant="body2">{institutionDegree.early_action}</Typography>
                </Grid>
              }

              {institutionDegree.early_action_date &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Action Date</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.early_action_date)}</Typography>
                </Grid>
              }

              {institutionDegree.prescreen_deadline_2 &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Action Prescreen Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.prescreen_deadline_2)}</Typography>
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Early Decision</Typography>
              </Grid>

              {institutionDegree.early_decision &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Decision</Typography>
                  <Typography variant="body2">{institutionDegree.early_decision}</Typography>
                </Grid>
              }

              {institutionDegree.early_decision_date &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Decision Date</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.early_decision_date)}</Typography>
                </Grid>
              }

              {institutionDegree.prescreen_deadline_3 &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Early Decision Prescreen Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.prescreen_deadline_3)}</Typography>
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Other Decision</Typography>
              </Grid>

              {institutionDegree.other_decision &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision</Typography>
                  <Typography variant="body2">{institutionDegree.other_decision}</Typography>
                </Grid>
              }

              {institutionDegree.other_decision_details &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision Details</Typography>
                  <Typography variant="body2">{institutionDegree.other_decision_details}</Typography>
                </Grid>
              }

              {institutionDegree.other_decision_date &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision Date</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.other_decision_date)}</Typography>
                </Grid>
              }

              {institutionDegree.prescreen_deadline_4 &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision Prescreen Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.prescreen_deadline_4)}</Typography>
                </Grid>
              }

            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Other Decision 2</Typography>
              </Grid>

              {institutionDegree.other_decision_two &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision 2</Typography>
                  <Typography variant="body2">{institutionDegree.other_decision_two}</Typography>
                </Grid>
              }

              {institutionDegree.other_decision_details_two &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision Details 2</Typography>
                  <Typography variant="body2">{institutionDegree.other_decision_details_two}</Typography>
                </Grid>
              }

              {institutionDegree.other_decision_date_two &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision Date 2</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.other_decision_date_two)}</Typography>
                </Grid>
              }

              {institutionDegree.prescreen_deadline_5 &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Other Decision 2 Prescreen Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.prescreen_deadline_5)}</Typography>
                </Grid>
              }
              

            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>

              <Grid item xs={12}>
                <Typography variant="h6">Optional Arts Supplement</Typography>
              </Grid>

              {institutionDegree.optional_arts_supplement &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Optional Arts Supplement</Typography>
                  <Typography variant="body2">{institutionDegree.optional_arts_supplement}</Typography>
                </Grid>
              }

              {institutionDegree.optional_arts_supplement_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Optional Arts Supplement Requirements</Typography>
                  <RichTextViewer value={institutionDegree.optional_arts_supplement_url} />
                </Grid>
              }

              {institutionDegree.optional_arts_supplement_deadline &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Optional Arts Supplement Deadline</Typography>
                  <Typography variant="body2">{formatDate(institutionDegree.optional_arts_supplement_deadline)}</Typography>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  )
}

export default InstitutionDegreeInfo;