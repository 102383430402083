import { Card, CardContent, CardHeader, Dialog, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material"
import * as React from "react"
import { InstitutionDegree } from '../../../../../../models';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { format, parseISO } from "date-fns";
import { useState } from "react";
import EditDegreeInfo from "./EditDegreeInfo";
import RichTextViewer from '../../../../../controls/RichTextViewer';
import EditableRichNotes from "../../../../../controls/EditableRichNotes";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

const DegreeInfo = ({ institutionDegree, isAdmin, onChange }: { institutionDegree: InstitutionDegree, isAdmin: boolean, onChange: (institutionDegree: InstitutionDegree) => void }) => {
  const collegeInstitutionDegree = institutionDegree;

  const [open, setOpen] = useState(false);
  // const [collegeInstitutionDegree, setCollegeInstitutionDegree] = useState(institutionDegree);

  const handleSaveInstitutionDegree = (updatedInstitutionDegree: InstitutionDegree) => {
    // setCollegeInstitutionDegree({...collegeInstitutionDegree, ...updatedInstitutionDegree});
    setOpen(false);
    onChange({...collegeInstitutionDegree, ...updatedInstitutionDegree});
  }

  const action = isAdmin ? (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null;

  const isValid = isValidPhoneNumber(collegeInstitutionDegree.admissions_office_phone || '');
  const phoneNumber = isValid ? parsePhoneNumber(collegeInstitutionDegree.admissions_office_phone || '') : null;
  const parsedPhoneNumber = phoneNumber ? phoneNumber.formatInternational() : collegeInstitutionDegree.admissions_office_phone

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader 
          title={collegeInstitutionDegree.degree?.name}
          action={action}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Degree Program Description Website:</Typography>
            <Link variant="body2" href={collegeInstitutionDegree.website_url}>{collegeInstitutionDegree.website_url}</Link>
          </Stack>
        
          {collegeInstitutionDegree.supplemental_application_required === 'Yes' &&
            <>
              {collegeInstitutionDegree.supplemental_application_deadline &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Supplemental Application Deadline:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.supplemental_application_deadline), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Supplemental Application Website:</Typography>
                <Link variant="body2" href={collegeInstitutionDegree.supplemental_application_url}>{collegeInstitutionDegree.supplemental_application_url}</Link>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Supplemental Application Reqs:</Typography>
                <RichTextViewer value={collegeInstitutionDegree.supplemental_application_requirements || ''} />
              </Stack>
            </>
          }

          {collegeInstitutionDegree.has_department_visit_information === 'Yes' &&
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">Department Visit Information:</Typography>
              <RichTextViewer value={collegeInstitutionDegree.department_visit_information || ''} />
            </Stack>
          }
          
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Admissions Office Phone:</Typography>
            <Typography variant="body2">{parsedPhoneNumber}</Typography>
          </Stack>

          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Admissions Office Email:</Typography>
            <Link variant="body2" href={`mailto:${collegeInstitutionDegree.admissions_office_email}`}>{collegeInstitutionDegree.admissions_office_email}</Link>
          </Stack>

          {collegeInstitutionDegree.optional_arts_supplement === 'Yes' &&
            <>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Optional Arts Supplement Requirements:</Typography>
                <RichTextViewer value={collegeInstitutionDegree.optional_arts_supplement_url || ''} />
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Optional Arts Supplement Deadline:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.optional_arts_supplement_deadline), 'MMM d, yyyy')}</Typography>
              </Stack>
            </>
          }
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="subtitle2">Comments About The Degree</Typography>
          <EditableRichNotes 
            endpoint={`institution_degrees/${collegeInstitutionDegree.id}`} 
            field="comments_about_degree" 
            value={collegeInstitutionDegree.comments_about_degree || ''} 
            wrap="institution_degree" 
            onChange={(value) => { onChange({ ...collegeInstitutionDegree, comments_about_degree: value }) }}
          />
        </CardContent>
      </Card>

      <Dialog open={open} fullWidth>
        <DialogTitle>{`${collegeInstitutionDegree.institution?.name} ${collegeInstitutionDegree.degree?.name}`}</DialogTitle>
        <EditDegreeInfo institutionDegree={collegeInstitutionDegree} onCancel={() => setOpen(false)} onSave={handleSaveInstitutionDegree} />
      </Dialog>
    </Stack>
  )
}

export default DegreeInfo