import { Alert, Box, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Assessment, InstitutionDegree, User } from "../../../../models";
import { admissionDecisionOptions, applicationStatusOptions, applyingOptions, statusOptions, testOptionalOptions } from "../../../../models/Assessment";
import PrimaryButton from "../../../controls/PrimaryButton";
import RichTextEditor from "../../../controls/RichTextEditor";
import SecondaryButton from "../../../controls/SecondaryButton";
import InstitutionDegreeDropdown from "../../../filters/InstitutionDegreeDropdown";

const prescreenDeadlineOptions = [
  ["Prescreen Deadline 1", "1"],
  ["Prescreen Deadline 2", "2"],
  ["Prescreen Deadline 3", "3"],
  ["Prescreen Deadline 4", "4"],
  ["Prescreen Deadline 5", "5"],
];

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const AssessmentForm = ({ user, assessment, error, onCancel, onSave }: { user: User, assessment?: Assessment, error?: string|null, onCancel: () => void, onSave: (assessment: Assessment) => void }) => {
  // const [institutionDegrees, setInstitutionDegrees] = useState<InstitutionDegree[]>([]);
  // const [institutionDegreeId, setInstitutionDegreeId] = useState(assessment?.institution_degree_id);
  const [institutionDegree, setInstitutionDegree] = useState<InstitutionDegree|null>(assessment?.institution_degree || null);
  const [academicConfidence, setAcademicConfidence] = useState(assessment?.academic_confidence || '');
  const [artisticConfidence, setArtisticConfidence] = useState(assessment?.artistic_confidence || '');
  const [scholarship, setScholarship] = useState(assessment?.scholarship || '');
  const [applicationStatus, setApplicationStatus] = useState(assessment?.application_status || '');
  const [applying, setApplying] = useState(assessment?.applying || '');
  const [prescreenDeadline, setPrescreenDeadline] = useState(assessment?.prescreen_deadline || '');
  const [admissionDecision, setAdmissionDecision] = useState(assessment?.admission_decision || '');
  const [prescreenArtisticUploadStatus, setPrescreenArtisticUploadStatus] = useState(assessment?.prescreen_artistic_upload_status || '');
  const [supplementalApplicationStatus, setSupplementalApplicationStatus] = useState(assessment?.supplemental_application_status || '');
  const [testOptional, setTestOptional] = useState(assessment?.test_optional || '');
  const [testOptionalOther, setTestOptionalOther] = useState(assessment?.test_optional_other || '');
  const [inactive, setInactive] = useState(assessment?.inactive || false);
  const [inactiveExplanation, setInactiveExplanation] = useState(assessment?.inactive_explanation || '');
  const [notes, setNotes] = useState(assessment?.notes || '');

  const [institutionDegreeError, setInstitutionDegreeError] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const handleSave = () => {
    setInstitutionDegreeError(!institutionDegree);

    if (!institutionDegree) {
      return;
    }

    const assessment: Assessment = {
      institution_degree_id: institutionDegree.id!,
      academic_confidence: academicConfidence,
      artistic_confidence: artisticConfidence,
      scholarship: scholarship,
      application_status: applicationStatus,
      applying: applying,
      prescreen_deadline: prescreenDeadline,
      admission_decision: admissionDecision,
      prescreen_artistic_upload_status: prescreenArtisticUploadStatus,
      supplemental_application_status: supplementalApplicationStatus,
      test_optional: testOptional,
      test_optional_other: testOptionalOther,
      inactive: inactive,
      inactive_explanation: inactiveExplanation,
      notes: notes,
    }
    onSave(assessment);
  }

  const assessmentApplicationStatusOptions = applicationStatusOptions(user);
  const assessmentApplyingOptions = applyingOptions(institutionDegree ? institutionDegree : undefined);

  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{ py: 1 }}>
        <Stack spacing={2}>
          {error &&
            <Alert severity="error"><Typography variant="body1">{error}</Typography></Alert>
          }

          <InstitutionDegreeDropdown value={institutionDegree} onChange={setInstitutionDegree} error={institutionDegreeError} />

          {!user?.gap_year &&
            <FormControl>
              <InputLabel id="academic_confidence">Academic Confidence</InputLabel>
              <Select
                labelId="academic_confidence"
                label="Academic Confidence"
                value={academicConfidence}
                onChange={(e) => setAcademicConfidence(e.target.value)}
              >
                <MenuItem value="">Please Select</MenuItem>
                <MenuItem value="Likely">Likely</MenuItem>
                <MenuItem value="Likely/Target">Likely/Target</MenuItem>
                <MenuItem value="Target">Target</MenuItem>
                <MenuItem value="Target/Stretch">Target/Stretch</MenuItem>
                <MenuItem value="Stretch">Stretch</MenuItem>
                <MenuItem value="High Stretch">High Stretch</MenuItem>
                <MenuItem value="Not Applicable">Not Applicable</MenuItem>
              </Select>
            </FormControl>
          }

          {!assessment?.user?.gap_year &&
            <FormControl>
              <InputLabel id="artistic_confidence">Artistic Confidence</InputLabel>
              <Select
                labelId="artistic_confidence"
                label="Artistic Confidence"
                value={artisticConfidence}
                onChange={(e) => setArtisticConfidence(e.target.value)}
              >
                <MenuItem value="">Please Select</MenuItem>
                <MenuItem value="Likely">Likely</MenuItem>
                <MenuItem value="Likely/Target">Likely/Target</MenuItem>
                <MenuItem value="Target">Target</MenuItem>
                <MenuItem value="Target/Stretch">Target/Stretch</MenuItem>
                <MenuItem value="Stretch">Stretch</MenuItem>
                <MenuItem value="High Stretch">High Stretch</MenuItem>
                <MenuItem value="Unknown">Unknown</MenuItem>
                <MenuItem value="Need Add'l Info/Student">Need Add'l Info/Student</MenuItem>
                <MenuItem value="Not Applicable">Not Applicable</MenuItem>
              </Select>
            </FormControl>
          }

          <TextField
            label="Scholarship"
            variant="outlined"
            value={scholarship}
            onChange={(e) => setScholarship(e.target.value)} />

          <FormControl>
            <InputLabel id="application_status">Application Status</InputLabel>
            <Select
              labelId="application_status"
              label="Application Status"
              value={applicationStatus}
              onChange={(e) => setApplicationStatus(e.target.value)}
            >
              {assessmentApplicationStatusOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  selected={(value || label) === applicationStatus}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="applying">Applying</InputLabel>
            <Select
              labelId="applying"
              label="Applying"
              value={applying}
              onChange={(e) => setApplying(e.target.value)}
            >
              {assessmentApplyingOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  selected={(value || label) === applying}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*institutionDegree?.multiple_prescreens === 'Yes' &&
            <FormControl>
              <InputLabel id="prescreenDeadline">Prescreen Deadline</InputLabel>
              <Select
                labelId="prescreenDeadline"
                label="Prescreen Deadline"
                value={prescreenDeadline}
                onChange={(e) => setPrescreenDeadline(e.target.value)}
              >
                {prescreenDeadlineOptions.map(([label, value]) => (
                  <MenuItem
                    key={value || label}
                    selected={(value || label) === prescreenDeadline}
                    value={value || label}
                  >{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          */}

          {institutionDegree?.pre_screening === 'Yes' &&
            <FormControl>
              <InputLabel id="admissionDecision">Prescreen Status</InputLabel>
              <Select
                labelId="admissionDecision"
                label="Prescreen Status"
                value={admissionDecision}
                onChange={(e) => setAdmissionDecision(e.target.value)}
              >
                {admissionDecisionOptions.map(([label, value]) => (
                  <MenuItem
                    key={value || label}
                    selected={(value || label) === admissionDecision}
                    value={value || label}
                  >{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }

          {/*institutionDegree?.pre_screening === 'Yes' && institutionDegree?.prescreen_artistic_upload_url &&
            <FormControl>
              <InputLabel id="prescreenArtisticUploadStatus">Prescreen Artistic Upload Status</InputLabel>
              <Select
                labelId="prescreenArtisticUploadStatus"
                label="Prescreen Artistic Upload Status"
                value={prescreenArtisticUploadStatus}
                onChange={(e) => setPrescreenArtisticUploadStatus(e.target.value)}
              >
                {statusOptions.map(([label, value]) => (
                  <MenuItem
                    key={value || label}
                    selected={(value || label) === prescreenArtisticUploadStatus}
                    value={value || label}
                  >{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          */}

          {institutionDegree?.supplemental_application_url &&
            <FormControl>
              <InputLabel id="supplementalApplicationStatus">Supplemental Application Status</InputLabel>
              <Select
                labelId="supplementalApplicationStatus"
                label="Supplemental Application Status"
                value={supplementalApplicationStatus}
                onChange={(e) => setSupplementalApplicationStatus(e.target.value)}
              >
                {statusOptions.map(([label, value]) => (
                  <MenuItem
                    key={value || label}
                    selected={(value || label) === supplementalApplicationStatus}
                    value={value || label}
                  >{label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }

          {institutionDegree?.score_optional_enabled === 'Yes' &&
            <>
              <FormControl>
                <InputLabel id="testOptional">Test Optional Recommendation</InputLabel>
                <Select
                  labelId="testOptional"
                  label="Test Optional Recommendation"
                  value={testOptional}
                  onChange={(e) => setTestOptional(e.target.value)}
                >
                  <MenuItem value="">Please Select</MenuItem>
                  {testOptionalOptions.map(([label, value]) => (
                    <MenuItem
                      key={value || label}
                      selected={(value || label) === testOptional}
                      value={value || label}
                    >{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {testOptional === 'Other' &&
                <TextField
                  label="Other Test Optional Recommendation"
                  variant="outlined"
                  value={testOptionalOther}
                  onChange={(e) => setTestOptionalOther(e.target.value)} />
              }
            </>
          }
          
          <FormControlLabel control={<Checkbox checked={inactive} onChange={() => setInactive(!inactive)} />} label="Deactivate" />
          {inactive &&
            <TextField
              label="Explanation"
              variant="outlined"
              value={inactiveExplanation}
              onChange={(e) => setInactiveExplanation(e.target.value)}
              sx={{ mb: 2 }} />
          }

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Notes</FormLabel>
            <RichTextEditor value={notes} onChange={setNotes} />
          </FormControl>

        </Stack>
      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default AssessmentForm;