import * as React from "react";
import { useEffect, useState } from "react";
import { Assessment, InstitutionDegree, Task } from "../../../models";
import TaskForm from "../../tasks/TaskForm";
import useAuth from "../../../hooks/useAuth";

const AddTask = ({ showUsers, onCancel, onSave }: { showUsers: boolean, onCancel: () => void, onSave: (tasks: Task[]) => void }) => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchAssessments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAssessments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchAssessments();
  }, []);
  
  
  const createTask = async (task: Task) => {
    const token = auth.token();

    const formData = new FormData();
    if (task.file) formData.append('attachment', task.file);
    formData.append('title', task.title);
    formData.append('notes', task.notes);
    formData.append('task_type', task.task_type);
    formData.append('due_on', task.due_on || '');
    // formData.append('user_ids', (task.user_ids || []).toString());
    formData.append('user_ids', [auth.user.id.toString()].toString());
    formData.append('include_collaborators', task.include_collaborators ? 'true' : 'false');
    formData.append('institution_degree_ids', (task.institution_degree_ids || []).toString());
    if (task.url) {
      formData.append('url', task.url);
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/batch`, {
      method: 'POST',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  const institutionDegrees: InstitutionDegree[] = assessments?.map(a => a.institution_degree)
    .filter((id): id is InstitutionDegree => !!id) || [];

  return (
    <TaskForm onCancel={handleCancel} onSave={createTask} showUsers={showUsers} institutionDegrees={institutionDegrees} />
  )
}

export default AddTask;