import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { User } from "../../models";
import useAuth from "../../hooks/useAuth";
import { fil } from "date-fns/locale";

const StudentDropdown = ({ value, onChange, filteredUsers = [] } : { value: User|null, onChange: (value: User|null) => void, filteredUsers: User[] }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [students, setStudents] = useState<User[]>(filteredUsers);
  const auth = useAuth();
  
  useEffect(() => {
    if (open) {
      const fetchStudents = async () => {
        console.log('fetch students after open')
        const token = auth.token();
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?student=1`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: User[] = await response.json();
          console.log(json);
          setStudents(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      if (filteredUsers.length === 0) fetchStudents();
    }
  }, [open, filteredUsers]);

  useEffect(() => {
    const fetchStudents = async () => {
      console.log('fetch students after input change')
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?student=1?q=${inputValue}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: User[] = await response.json();
        console.log(json);
        setStudents(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    if (filteredUsers.length === 0) fetchStudents();
  }, [inputValue, filteredUsers]);

  useEffect(() => {
    setStudents(filteredUsers)
  }, [filteredUsers])

  const handleChange = (event: SyntheticEvent, value: (User|null), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const sortedStudents = students.sort((a, b) => {
    const aName = `${a.last_name.toLowerCase()}, ${a.first_name.toLowerCase()}`;
    const bName = `${b.last_name.toLowerCase()}, ${b.first_name.toLowerCase()}`;
    return aName.localeCompare(bName);
  });

  if (value) {
    if (!sortedStudents.find((i) => i.id === value.id)) {
      sortedStudents.push(value);
    }
  }

  console.log('students', sortedStudents)
  
  return (
    <Autocomplete 
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Student"
        />
      )} 
      value={value}
      options={sortedStudents}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.first_name} ${option.last_name}`}
      onChange={(event, value, reason) => handleChange(event, value as User, reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  )
}

export default StudentDropdown;
