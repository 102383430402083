import { Box, Card, CardContent, Chip, Container, Dialog, FormControl, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Assessment, Collaboration } from "../../../models";
import TertiaryButton from "../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FilterAssessments, { FilterData } from "../../filters/FilterAssessments";
import SortAssessments from "../../sort/SortAssessments";
import SortOptions from "../../sort/SortOptions";
import useUser from "../../../hooks/useUser";
import useAuth from "../../../hooks/useAuth";
import SearchIcon from '@mui/icons-material/Search';
import SearchField from "../../controls/SearchField";
import AssessmentListItem from "../../assessments/AssessmentListItem";
import { useSearchParams } from "react-router-dom";

const perPage = 100;

const Colleges = () => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);

  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const auth = useAuth();
  const currentUser = useUser();

  const [reachedEnd, setReachedEnd] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [invitations, setInvitations] = useState<Collaboration[]>([]);
  const [taskGroup, setTaskGroup] = useState('all');

  const applicationStatus = searchParams.get('status');
  if (applicationStatus && !filters) {
    const graduationYear = new Date().getMonth() >= 5 ? new Date().getFullYear() + 1 : new Date().getFullYear();
    setFilters({ 
      application_status: applicationStatus as string, 
      college: null, 
      degree: null, 
      graduation_year: graduationYear.toString(),
      supplemental_application_status: null,
      admission_decision: null,
    });
  }

  const supplementalStatus = searchParams.get('supplemental_status');
  if (supplementalStatus && !filters) {
    const graduationYear = new Date().getMonth() >= 5 ? new Date().getFullYear() + 1 : new Date().getFullYear();
    setFilters({ 
      supplemental_application_status: supplementalStatus as string, 
      college: null, 
      degree: null, 
      graduation_year: graduationYear.toString(), 
      application_status: null,
      admission_decision: null,
    });
  }

  const prescreenStatus = searchParams.get('prescreen_status');
  if (prescreenStatus && !filters) {
    const graduationYear = new Date().getMonth() >= 5 ? new Date().getFullYear() + 1 : new Date().getFullYear();
    setFilters({ 
      admission_decision: prescreenStatus as string, 
      college: null, 
      degree: null, 
      graduation_year: graduationYear.toString(), 
      application_status: null,
      supplemental_application_status: null,
    });
  }

  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const abortController = new AbortController();

    const params: string[] = [];

    if (sortOptions) {
      params.push(`sort=${sortOptions.column}`);
      params.push(`direction=${sortOptions.direction}`);
    }

    if (filters) {
      Object.keys(filters).map((key, index) => {
        const value = filters[key];
        if (value) {
          if (key === 'college') {
            params.push(`${key}=${value?.id || ''}`);
          } else if (key === 'degree') {
            params.push(`${key}=${value?.id || ''}`);
          } else {
            params.push(`${key}=${value}`);
          }
        }
      })
    }

    const filterParams = params.join('&');

    const fetchAssessments = async () => {
      setLoading(true);
      // const userId = currentUser.userId || auth.user.id;
      const currentUserId = auth.user.id;

      const token = auth.token();
      
      let url = `${window.SERVER_DATA.domain}/api/v1/assessments`;
      if (taskGroup === 'me') {
        url = `${url}?q=${searchQuery}&page=${page}&per_page=${perPage}&user_id=${currentUserId}`;
      } else if (taskGroup === 'all') {
        url = `${url}?q=${searchQuery}&page=${page}&per_page=${perPage}&collaborator=1`;
      } else {
        url = `${url}?q=${searchQuery}&page=${page}&per_page=${perPage}&user_id=${taskGroup}`;
      }
      if (filterParams) {
        url = `${url}&${filterParams}`;
      }
      
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        if (page === 1) {
          setAssessments(json);
        } else {
          setAssessments(prev => {
            const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchAssessments();

    return () => {
      abortController.abort();
    };
  }, [auth, currentUser, filters, sortOptions, searchQuery, page, taskGroup]);

  useEffect(() => {
    const fetchInvitations = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/invitations`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setInvitations(json);
    }

    fetchInvitations();
  }, [])

  const handleSaveFilters = (filters: FilterData) => {
    setFilters(filters);
    setOpenFilters(false);
  }

  const handleSaveSort = (options: SortOptions) => {
    setSortOptions(options);
    setOpenSort(false);
  }

  const handleAssessmentChange = (assessment: Assessment) => {
    setAssessments(prev => prev.map(a => a.id === assessment.id ? assessment : a));
  }

  const filteredAssessments = assessments.filter(a => {
    let include = true;

    if (filters?.prescreens && filters?.prescreens !== 'Any' && a.institution_degree?.pre_screening !== filters.prescreens) include = false;
    if (filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && a.institution_degree?.required_audition_portfolio !== filters.auditions_portfolio_required) include = false;
    if (filters?.early_decision && filters?.early_decision !== 'Any' && a.institution_degree?.early_decision !== filters.early_decision) include = false;
    if (filters?.early_action && filters?.early_action !== 'Any' && a.institution_degree?.early_action !== filters.early_action) include = false;
    if (filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && a.institution_degree?.score_optional_enabled !== filters.score_optional_enabled) include = false;
    if (filters?.common_app && filters?.common_app !== 'Any' && a.institution_degree?.institution?.common_app !== filters.common_app) include = false;
    if (filters?.audition_date_start && filters?.audition_date_start !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates < filters.audition_date_start) include = false;
    if (filters?.audition_date_end && filters?.audition_date_end !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates > filters.audition_date_end) include = false;
    if (filters?.graduation_year && filters?.graduation_year !== 'Any' && a.user?.high_school_graduation_year?.toString() !== filters.graduation_year.toString()) include = false;
    if (filters?.college && a.institution_degree?.institution_id !== filters.college.id) include = false;
    if (filters?.degree && a.institution_degree?.degree_id !== filters.degree.id) include = false;
    if (filters?.applying && filters?.applying !== 'Any' && a.applying !== filters.applying) include = false;
    if (filters?.test_optional && filters?.test_optional !== 'Any' && a.test_optional !== filters.test_optional) include = false;
    if (filters?.academic_confidence && filters?.academic_confidence !== 'Any' && a.academic_confidence !== filters.academic_confidence) include = false;
    if (filters?.artistic_confidence && filters?.artistic_confidence !== 'Any' && a.artistic_confidence !== filters.artistic_confidence) include = false;
    if (filters?.unified_auditions && filters?.unified_auditions !== 'Any' && a.institution_degree?.unified_auditions !== filters.unified_auditions) include = false;
    if (
      filters?.application_status && filters?.application_status !== 'Any' && 
      (
        (filters?.application_status === 'Not Selected' && a.application_status) || 
        (filters?.application_status !== 'Not Selected' && a.application_status !== filters?.application_status)
      )
    ) include = false;
    if (
      filters?.supplemental_application_status && filters?.supplemental_application_status !== 'Any' && 
      (
        a.institution_degree?.supplemental_application_required !== 'Yes' ||
        !a.institution_degree?.supplemental_application_url ||
        (
          (filters?.supplemental_application_status === 'Not Selected' && a.supplemental_application_status) || 
          (filters?.supplemental_application_status !== 'Not Selected' && a.supplemental_application_status !== filters?.supplemental_application_status)
        )
      )
    ) include = false;
    if (
      filters?.admission_decision && filters?.admission_decision !== 'Any' &&
      (
        a.institution_degree?.pre_screening !== 'Yes' ||
        (
          (filters?.admission_decision === 'Not Selected' && a.admission_decision) || 
          (filters?.admission_decision !== 'Not Selected' && a.admission_decision !== filters?.admission_decision)
        )
      )
    ) include = false;
    return include;
  });

  const sortedAssessments = filteredAssessments.sort((a, b) => {
    let sort = 0;
    if (sortOptions?.column === 'lower(institutions.name)') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    } else if (sortOptions?.column === 'lower(degrees.name)') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.degree?.name || '').localeCompare(a.institution_degree?.degree?.name || '');
      } else {
        sort = sort || (a.institution_degree?.degree?.name || '').localeCompare(b.institution_degree?.degree?.name || '');
      }
    } else if (sortOptions?.column === 'calc_application_deadline') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.calc_application_deadline || '').localeCompare(a.calc_application_deadline || '');
      } else {
        sort = sort || (a.calc_application_deadline || '').localeCompare(b.calc_application_deadline || '');
      }
    } else if (sortOptions?.column === 'calc_prescreen_deadline') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.calc_prescreen_deadline || '').localeCompare(a.calc_prescreen_deadline || '');
      } else {
        sort = sort || (a.calc_prescreen_deadline || '').localeCompare(b.calc_prescreen_deadline || '');
      }
    } else if (sortOptions?.column === 'artistic_confidence') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.artistic_confidence || '').localeCompare(a.artistic_confidence || '');
      } else {
        sort = sort || (a.artistic_confidence || '').localeCompare(b.artistic_confidence || '');
      }
    } else if (sortOptions?.column === 'academic_confidence') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.academic_confidence || '').localeCompare(a.academic_confidence || '');
      } else {
        sort = sort || (a.academic_confidence || '').localeCompare(b.academic_confidence || '');
      }
    } else {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    }
    return sort;
  });

  const handleChangeTaskGroup = (value: string) => {
    setReachedEnd(false);
    setPage(1);
    setTaskGroup(value);
  }

  const filteredInvitations = invitations.filter(invitation => invitation.user?.id);

  return (
    <Box>
      <Stack spacing={1} sx={{
        pb: 2,
        px: 2,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}>
        <Stack spacing={2} direction="row">
          <TertiaryButton
            variant="text"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpenFilters(true)}
          >
            Filter Colleges
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<ImportExportOutlinedIcon />}
            onClick={() => setOpenSort(true)}
          >
            Sort Colleges
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (openSearch) {
                setOpenSearch(false);
                setSearchQuery('');
              } else {
                setOpenSearch(true);
              }
            }}
          >
            Search Colleges
          </TertiaryButton>
        </Stack>
        {openSearch &&
          <SearchField label="Search Colleges" onSearch={setSearchQuery} realTime={true} />
        }
      </Stack>

      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
            {filteredInvitations.length > 0 &&
              <FormControl>
                <Select
                  sx={{ background: 'white' }}
                  value={taskGroup}
                  onChange={(e) => handleChangeTaskGroup(e.target.value)}
                  size="small"
                >
                  <MenuItem value="all">All Colleges</MenuItem>
                  <MenuItem value="me">My Colleges</MenuItem>
                  {filteredInvitations.map((invitation) => 
                    <MenuItem value={invitation.user?.id}>{`${invitation.user?.first_name} ${invitation.user?.last_name}`} Colleges</MenuItem>
                  )}
                </Select>
              </FormControl>
            }
          </Stack>

          <Card>
            <CardContent>
              <Stack spacing={1}>
                <Stack spacing={2} direction="row">
                  <Stack direction="row" spacing={2} sx={{flexGrow: 1}}>
                    <Typography variant="h6">Active Colleges</Typography>
                    <Chip label={sortedAssessments.length} size="small" color="primary" />
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>College</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Confidence</TableCell>
                    <TableCell>Application Deadline</TableCell>
                    {filters?.prescreens && filters?.prescreens !== 'Any' && <TableCell>Prescreens</TableCell>}
                    {filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && <TableCell>Auditions/Portfolio Required</TableCell>}
                    {filters?.early_decision && filters?.early_decision !== 'Any' && <TableCell>Early Decision</TableCell>}
                    {filters?.early_action && filters?.early_action !== 'Any' && <TableCell>Early Action</TableCell>}
                    {filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && <TableCell>Score Optional</TableCell>}
                    {filters?.common_app && filters?.common_app !== 'Any' && <TableCell>Common App</TableCell>}
                    {(filters?.audition_date_start || filters?.audition_date_end) && <TableCell>Audition Date</TableCell>}
                    {filters?.graduation_year && filters?.graduation_year !== 'Any' && <TableCell>Year of Intended Enrollment</TableCell>}
                    {filters?.test_optional && filters?.test_optional !== 'Any' && <TableCell>Test Optional Recommendation</TableCell>}
                    {filters?.unified_auditions && filters?.unified_auditions !== 'Any' && <TableCell>Unified Auditions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedAssessments.map(assessment => (
                    <AssessmentListItem key={assessment.id} assessment={assessment} filters={filters} isAdmin={false} onChange={handleAssessmentChange} />
                  ))}
                  {sortedAssessments.length === 0 &&
                    <TableRow>
                      <TableCell colSpan={10}>No colleges found</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Stack>
      </Container>

      <Dialog open={openFilters} fullWidth>
        <FilterAssessments title="Filter Colleges" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} />
      </Dialog>

      <Dialog open={openSort} fullWidth>
        <SortAssessments title="Sort Colleges" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>
    </Box>
  )
}

export default Colleges;