import { Box, Container, Divider, FormControl, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import { MuiTelInput } from "mui-tel-input";
import * as React from "react";
import { useEffect, useState } from "react";
import { Degree, InstitutionDegree } from "../../../../models";
import PrimaryButton from "../../../controls/PrimaryButton";
import RichTextEditor from "../../../controls/RichTextEditor";
import SecondaryButton from "../../../controls/SecondaryButton";
import DegreeDropdown from "../../../filters/DegreeDropdown";


const InstitutionDegreeForm = ({ institutionId, institutionDegree, onCancel, onSave }: { institutionId: number, institutionDegree?: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  
  let institutionDegreeSupplementalApplicationDeadline = '';
  if (institutionDegree?.supplemental_application_deadline) {
    const date = parseISO(institutionDegree.supplemental_application_deadline);
    institutionDegreeSupplementalApplicationDeadline = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePreScreeningDeadline = '';
  if (institutionDegree?.pre_screening_deadline) {
    const date = parseISO(institutionDegree.pre_screening_deadline);
    institutionDegreePreScreeningDeadline = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline2 = '';
  if (institutionDegree?.prescreen_deadline_2) {
    const date = parseISO(institutionDegree.prescreen_deadline_2);
    institutionDegreePrescreenDeadline2 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline3 = '';
  if (institutionDegree?.prescreen_deadline_3) {
    const date = parseISO(institutionDegree.prescreen_deadline_3);
    institutionDegreePrescreenDeadline3 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline4 = '';
  if (institutionDegree?.prescreen_deadline_4) {
    const date = parseISO(institutionDegree.prescreen_deadline_4);
    institutionDegreePrescreenDeadline4 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline5 = '';
  if (institutionDegree?.prescreen_deadline_5) {
    const date = parseISO(institutionDegree.prescreen_deadline_5);
    institutionDegreePrescreenDeadline5 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreEarlyActionDate = '';
  if (institutionDegree?.early_action_date) {
    const date = parseISO(institutionDegree.early_action_date);
    institutionDegreEarlyActionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreEarlyDecisionDate = '';
  if (institutionDegree?.early_decision_date) {
    const date = parseISO(institutionDegree.early_decision_date);
    institutionDegreEarlyDecisionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreOtherDecisionDate = '';
  if (institutionDegree?.other_decision_date) {
    const date = parseISO(institutionDegree.other_decision_date);
    institutionDegreOtherDecisionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreOtherDecisionDateTwo = '';
  if (institutionDegree?.other_decision_date_two) {
    const date = parseISO(institutionDegree.other_decision_date_two);
    institutionDegreOtherDecisionDateTwo = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreeRegularDeadline = '';
  if (institutionDegree?.regular_deadline) {
    const date = parseISO(institutionDegree.regular_deadline);
    institutionDegreeRegularDeadline = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreeArtsBridgeRecommendedDeadline = '';
  if (institutionDegree?.artsbridge_recommended_deadline) {
    const date = parseISO(institutionDegree.artsbridge_recommended_deadline);
    institutionDegreeArtsBridgeRecommendedDeadline = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreeOptionalArtsSupplmentDeadline = '';
  if (institutionDegree?.optional_arts_supplement_deadline) {
    const date = parseISO(institutionDegree.optional_arts_supplement_deadline);
    institutionDegreeOptionalArtsSupplmentDeadline = format(date, 'yyyy-MM-dd');
  }
  
  const [degree, setDegree] = useState(institutionDegree?.degree || null);
  const [websiteUrl, setWebsiteUrl] = useState(institutionDegree?.website_url || '');
  const [supplementalApplicationRequired, setSupplementalApplicationRequired] = useState(institutionDegree?.supplemental_application_required || '');
  const [supplementalApplicationDeadline, setSupplementalApplicationDeadline] = useState(institutionDegreeSupplementalApplicationDeadline);
  const [supplementalApplicationUrl, setSupplementalApplicationUrl] = useState(institutionDegree?.supplemental_application_url || '');
  const [supplementalApplicationRequirements, setSupplementalApplicationRequirements] = useState(institutionDegree?.supplemental_application_requirements || '');
  const [preScreening, setPreScreening] = useState(institutionDegree?.pre_screening || '');
  const [preScreeningDeadline, setPreScreeningDeadline] = useState(institutionDegreePreScreeningDeadline);
  const [multiplePrescreens, setMultiplePrescreens] = useState(institutionDegree?.multiple_prescreens || '');
  const [prescreenDeadline2, setPrescreenDeadline2] = useState(institutionDegreePrescreenDeadline2);
  const [prescreenDeadline3, setPrescreenDeadline3] = useState(institutionDegreePrescreenDeadline3);
  const [prescreenDeadline4, setPrescreenDeadline4] = useState(institutionDegreePrescreenDeadline4);
  const [prescreenDeadline5, setPrescreenDeadline5] = useState(institutionDegreePrescreenDeadline5);
  const [preScreeningRequirementsUrl, setPreScreeningRequirementsUrl] = useState(institutionDegree?.pre_screening_requirements_url || '');
  const [prescreenArtisticUploadUrl, setPrescreenArtisticUploadUrl] = useState(institutionDegree?.prescreen_artistic_upload_url || '');
  const [scoreOptionalEnabled, setScoreOptionalEnabled] = useState(institutionDegree?.score_optional_enabled || '');
  const [scoreOptional, setScoreOptional] = useState(institutionDegree?.score_optional || '');
  const [writingRequirements, setWritingRequirements] = useState(institutionDegree?.writing_requirements || '');
  const [requiredAuditionPortfolio, setRequiredAuditionPortfolio] = useState(institutionDegree?.required_audition_portfolio || '');
  const [schedulingUrl, setSchedulingUrl] = useState(institutionDegree?.scheduling_url || '');
  const [schedulingInstructions, setSchedulingInstructions] = useState(institutionDegree?.scheduling_instructions || '');
  const [unifiedAuditions, setUnifiedAuditions] = useState(institutionDegree?.unified_auditions || '');
  const [auditionDates, setAuditionDates] = useState(institutionDegree?.audition_dates || '');
  const [inPersonAuditionRequirements, setInPersonAuditionRequirements] = useState(institutionDegree?.in_person_audition_requirements || '');
  const [commentsAboutDegree, setCommentsAboutDegree] = useState(institutionDegree?.comments_about_degree || '');
  const [hasDepartmentVisitInformation, setHasDepartmentVisitInformation] = useState(institutionDegree?.has_department_visit_information || '');
  const [departmentVisitInformation, setDepartmentVisitInformation] = useState(institutionDegree?.department_visit_information || '');
  const [admissionsOfficePhone, setAdmissionsOfficePhone] = useState(institutionDegree?.admissions_office_phone || '');
  const [admissionsOfficeEmail, setAdmissionsOfficeEmail] = useState(institutionDegree?.admissions_office_email || '');
  const [earlyAction, setEarlyAction] = useState(institutionDegree?.early_action || '');
  const [earlyActionDate, setEarlyActionDate] = useState(institutionDegreEarlyActionDate);
  const [earlyDecision, setEarlyDecision] = useState(institutionDegree?.early_decision || '');
  const [earlyDecisionDate, setEarlyDecisionDate] = useState(institutionDegreEarlyDecisionDate);
  const [otherDecision, setOtherDecision] = useState(institutionDegree?.other_decision || '');
  const [otherDecisionDetails, setOtherDecisionDetails] = useState(institutionDegree?.other_decision_details || '');
  const [otherDecisionDate, setOtherDecisionDate] = useState(institutionDegreOtherDecisionDate);
  const [otherDecisionTwo, setOtherDecisionTwo] = useState(institutionDegree?.other_decision_two || '');
  const [otherDecisionDetailsTwo, setOtherDecisionDetailsTwo] = useState(institutionDegree?.other_decision_details_two || '');
  const [otherDecisionDateTwo, setOtherDecisionDateTwo] = useState(institutionDegreOtherDecisionDateTwo);
  const [regularDeadline, setRegularDeadline] = useState(institutionDegreeRegularDeadline);
  const [artsBridgeRecommendedDeadline, setArtsBridgeRecommendedDeadline] = useState(institutionDegreeArtsBridgeRecommendedDeadline);
  const [optionalArtsSupplement, setOptionalArtsSupplement] = useState(institutionDegree?.optional_arts_supplement || '');
  const [optionalArtsSupplementUrl, setOptionalArtsSupplementUrl] = useState(institutionDegree?.optional_arts_supplement_url || '');
  const [optionalArtsSupplementDeadline, setOptionalArtsSupplementDeadline] = useState(institutionDegreeOptionalArtsSupplmentDeadline);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;
  
  const handleSave = () => {
    if (!degree) return;
    
    const institutionDegree: InstitutionDegree = {
      degree_id: degree.id!,
      institution_id: institutionId,
      website_url: websiteUrl,
      supplemental_application_required: supplementalApplicationRequired,
      supplemental_application_deadline: supplementalApplicationDeadline,
      supplemental_application_url: supplementalApplicationUrl,
      supplemental_application_requirements: supplementalApplicationRequirements,
      pre_screening: preScreening,
      pre_screening_deadline: preScreeningDeadline,
      multiple_prescreens: multiplePrescreens,
      prescreen_deadline_2: prescreenDeadline2,
      prescreen_deadline_3: prescreenDeadline3,
      prescreen_deadline_4: prescreenDeadline4,
      prescreen_deadline_5: prescreenDeadline5,
      pre_screening_requirements_url: preScreeningRequirementsUrl,
      prescreen_artistic_upload_url: prescreenArtisticUploadUrl,
      score_optional_enabled: scoreOptionalEnabled,
      score_optional: scoreOptional,
      writing_requirements: writingRequirements,
      required_audition_portfolio: requiredAuditionPortfolio,
      scheduling_url: schedulingUrl,
      scheduling_instructions: schedulingInstructions,
      unified_auditions: unifiedAuditions,
      audition_dates: auditionDates,
      in_person_audition_requirements: inPersonAuditionRequirements,
      comments_about_degree: commentsAboutDegree,
      has_department_visit_information: hasDepartmentVisitInformation,
      department_visit_information: departmentVisitInformation,
      admissions_office_phone: admissionsOfficePhone,
      admissions_office_email: admissionsOfficeEmail,
      early_action: earlyAction,
      early_action_date: earlyActionDate,
      early_decision: earlyDecision,
      early_decision_date: earlyDecisionDate,
      other_decision: otherDecision,
      other_decision_details: otherDecisionDetails,
      other_decision_date: otherDecisionDate,
      other_decision_two: otherDecisionTwo,
      other_decision_details_two: otherDecisionDetailsTwo,
      other_decision_date_two: otherDecisionDateTwo,
      regular_deadline: regularDeadline,
      artsbridge_recommended_deadline: artsBridgeRecommendedDeadline,
      optional_arts_supplement: optionalArtsSupplement,
      optional_arts_supplement_url: optionalArtsSupplementUrl,
      optional_arts_supplement_deadline: optionalArtsSupplementDeadline,
    }
    onSave(institutionDegree);
  }

  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{py: 2}}>
        <Stack spacing={2}>
          
          <Typography variant="h6">Degree Info</Typography>

          <DegreeDropdown value={degree} onChange={setDegree} />

          <TextField 
            label="Degree Program Description Website" 
            variant="outlined" 
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)} />

          <MuiTelInput 
            label="Admissions Office Phone" 
            size="small" 
            forceCallingCode={true} 
            defaultCountry="US" 
            // disableDropdown 
            value={admissionsOfficePhone} 
            onChange={setAdmissionsOfficePhone} />

          <TextField 
            label="Admissions Office Email" 
            variant="outlined"
            value={admissionsOfficeEmail}
            onChange={(e) => setAdmissionsOfficeEmail(e.target.value)} />

          <TextField
            label="ArtsBridge Recommended Deadline"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={artsBridgeRecommendedDeadline}
            onChange={(e) => setArtsBridgeRecommendedDeadline(e.target.value)}
          />

          <FormControl>
            <InputLabel id="scoreOptionalEnabled">Score Optional</InputLabel>
            <Select
              labelId="scoreOptionalEnabled"
              label="Score Optional"
              value={scoreOptionalEnabled || ''}
              onChange={(e) => setScoreOptionalEnabled(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Score Optional Policy</FormLabel>
            <RichTextEditor value={scoreOptional} onChange={setScoreOptional} />
          </FormControl>

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Comments about the degree</FormLabel>
            <RichTextEditor value={commentsAboutDegree} onChange={setCommentsAboutDegree} />
          </FormControl>

          <Divider/>
          <Typography variant="h6">Supplemental Application</Typography>

          <FormControl>
            <InputLabel id="supplementalApplicationRequired">Supplemental Application Required</InputLabel>
            <Select
              labelId="supplementalApplicationRequired"
              label="Supplemental Application Required"
              value={supplementalApplicationRequired || ''}
              onChange={(e) => setSupplementalApplicationRequired(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {supplementalApplicationRequired === 'Yes' && 
            <>
              <TextField
                label="Supplemental Application Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={supplementalApplicationDeadline}
                onChange={(e) => setSupplementalApplicationDeadline(e.target.value)}
              />
            
              <TextField 
                label="Supplemental Application Website" 
                variant="outlined" 
                value={supplementalApplicationUrl}
                onChange={(e) => setSupplementalApplicationUrl(e.target.value)} />

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Supplemental Application Requirements</FormLabel>
                <RichTextEditor value={supplementalApplicationRequirements} onChange={setSupplementalApplicationRequirements} />
              </FormControl>
            </>
          }

          <Divider/>
          <Typography variant="h6">Prescreen</Typography>

          <FormControl>
            <InputLabel id="preScreening">Prescreen Required</InputLabel>
            <Select
              labelId="preScreening"
              label="Prescreen Required"
              value={preScreening || ''}
              onChange={(e) => setPreScreening(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {preScreening &&
            <>
              {/*
              <FormControl>
                <InputLabel id="multiplePrescreens">Multiple Prescreens</InputLabel>
                <Select
                  labelId="multiplePrescreens"
                  label="Multiple Prescreens"
                  value={multiplePrescreens || ''}
                  onChange={(e) => setMultiplePrescreens(e.target.value)}
                >
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>
              */}

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Prescreen Requirements</FormLabel>
                <RichTextEditor value={preScreeningRequirementsUrl} onChange={setPreScreeningRequirementsUrl} />
              </FormControl>

              <TextField 
                label="Prescreen Artistic Upload Website" 
                variant="outlined" 
                value={prescreenArtisticUploadUrl}
                onChange={(e) => setPrescreenArtisticUploadUrl(e.target.value)} />

            </>
          }

          <Divider/>
          <Typography variant="h6">Audition/Portfolio</Typography>

          <FormControl>
            <InputLabel id="requiredAuditionPortfolio">Audition/Portfolio Required</InputLabel>
            <Select
              labelId="requiredAuditionPortfolio"
              label="Audition/Portfolio Required"
              value={requiredAuditionPortfolio || ''}
              onChange={(e) => setRequiredAuditionPortfolio(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="Optional">Optional</MenuItem>
            </Select>
          </FormControl>

          {(requiredAuditionPortfolio === 'Yes' || requiredAuditionPortfolio === 'Optional') &&
            <>
              <TextField 
                label="Scheduling Website" 
                variant="outlined" 
                value={schedulingUrl}
                onChange={(e) => setSchedulingUrl(e.target.value)} />

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Scheduling Instructions</FormLabel>
                <RichTextEditor value={schedulingInstructions} onChange={setSchedulingInstructions} />
              </FormControl>

              <FormControl>
                <InputLabel id="unifiedAuditions">Unified Auditions</InputLabel>
                <Select
                  labelId="unifiedAuditions"
                  label="Unified Auditions"
                  value={unifiedAuditions || ''}
                  onChange={(e) => setUnifiedAuditions(e.target.value)}
                >
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Audition/Portfolio Dates</FormLabel>
                <RichTextEditor value={auditionDates} onChange={setAuditionDates} />
              </FormControl>

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Audition/Portfolio Requirements</FormLabel>
                <RichTextEditor value={inPersonAuditionRequirements} onChange={setInPersonAuditionRequirements} />
              </FormControl>

            </>
          }

          <Divider/>
          <Typography variant="h6">Department Visit Information</Typography>

          <FormControl>
            <InputLabel id="hasDepartmentVisitInformation">Has Department Visit Information</InputLabel>
            <Select
              labelId="hasDepartmentVisitInformation"
              label="Has Department Visit Information"
              value={hasDepartmentVisitInformation || ''}
              onChange={(e) => setHasDepartmentVisitInformation(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {hasDepartmentVisitInformation === 'Yes' &&
            <FormControl sx={{ fontSize: 14, mb: 1 }}>
              <FormLabel sx={{ fontSize: 12, pb: 1 }}>Department Visit Information</FormLabel>
              <RichTextEditor value={departmentVisitInformation} onChange={setDepartmentVisitInformation} />
            </FormControl>
          }

          <Divider/>
          <Typography variant="h6">Regular Decision</Typography>

          <TextField
            label="Regular Deadline"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={regularDeadline}
            onChange={(e) => setRegularDeadline(e.target.value)}
          />
          {preScreening === 'Yes' &&
            <TextField
              label="Regular Decision Prescreen Deadline"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={preScreeningDeadline}
              onChange={(e) => setPreScreeningDeadline(e.target.value)}
            />
          }


          <Divider/>
          <Typography variant="h6">Early Action</Typography>

          <FormControl>
            <InputLabel id="earlyAction">Early Action</InputLabel>
            <Select
              labelId="earlyAction"
              label="Early Action"
              value={earlyAction || ''}
              onChange={(e) => setEarlyAction(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {earlyAction === 'Yes' &&
            <>
              <TextField
                label="Early Action Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={earlyActionDate}
                onChange={(e) => setEarlyActionDate(e.target.value)}
              />
              {preScreening === 'Yes' &&
                <TextField
                  label="Early Action Prescreen Deadline"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prescreenDeadline2}
                  onChange={(e) => setPrescreenDeadline2(e.target.value)}
                />
              }
            </>
          }

          <Divider/>
          <Typography variant="h6">Early Decision</Typography>

          <FormControl>
            <InputLabel id="earlyDecision">Early Decision</InputLabel>
            <Select
              labelId="earlyDecision"
              label="Early Decision"
              value={earlyDecision || ''}
              onChange={(e) => setEarlyDecision(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {earlyDecision === 'Yes' &&
            <>
              <TextField
                label="Early Decision Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={earlyDecisionDate}
                onChange={(e) => setEarlyDecisionDate(e.target.value)}
              />
              {preScreening === 'Yes' &&
                <TextField
                  label="Early Decision Prescreen Deadline"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prescreenDeadline3}
                  onChange={(e) => setPrescreenDeadline3(e.target.value)}
                />
              }
            </>
          }

          <Divider/>
          <Typography variant="h6">Other Decision</Typography>

          <FormControl>
            <InputLabel id="otherDecision">Other Decision</InputLabel>
            <Select
              labelId="otherDecision"
              label="Other Decision"
              value={otherDecision || ''}
              onChange={(e) => setOtherDecision(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {otherDecision === 'Yes' &&
            <>
              <TextField 
                label="Other Decision Details" 
                variant="outlined"
                value={otherDecisionDetails}
                onChange={(e) => setOtherDecisionDetails(e.target.value)} />

              <TextField
                label="Other Decision Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={otherDecisionDate}
                onChange={(e) => setOtherDecisionDate(e.target.value)}
              />
              {preScreening === 'Yes' &&
                <TextField
                  label="Other Decision Prescreen Deadline"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prescreenDeadline4}
                  onChange={(e) => setPrescreenDeadline4(e.target.value)}
                />
              }
            </>
          }

          <Divider/>
          <Typography variant="h6">Other Decision 2</Typography>

          <FormControl>
            <InputLabel id="otherDecisionTwo">Other Decision 2</InputLabel>
            <Select
              labelId="otherDecisionTwo"
              label="Other Decision 2"
              value={otherDecisionTwo || ''}
              onChange={(e) => setOtherDecisionTwo(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {otherDecisionTwo === 'Yes' &&
            <>
              <TextField 
                label="Other Decision Details 2" 
                variant="outlined"
                value={otherDecisionDetailsTwo}
                onChange={(e) => setOtherDecisionDetailsTwo(e.target.value)} />

              <TextField
                label="Other Decision Date 2"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={otherDecisionDateTwo}
                onChange={(e) => setOtherDecisionDateTwo(e.target.value)}
              />

              {preScreening === 'Yes' &&
                <TextField
                  label="Other Decision 2 Prescreen Deadline"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={prescreenDeadline5}
                  onChange={(e) => setPrescreenDeadline5(e.target.value)}
                />
              }
            </>
          }

          <Divider/>
          <Typography variant="h6">Optional Arts Supplement</Typography>

          <FormControl>
            <InputLabel id="optionalArtsSupplement">Optional Arts Supplement</InputLabel>
            <Select
              labelId="optionalArtsSupplement"
              label="Optional Arts Supplement"
              value={optionalArtsSupplement || ''}
              onChange={(e) => setOptionalArtsSupplement(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {optionalArtsSupplement === 'Yes' && 
            <>
              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Optional Arts Supplement Requirements</FormLabel>
                <RichTextEditor value={optionalArtsSupplementUrl} onChange={setOptionalArtsSupplementUrl} />
              </FormControl>

              <TextField
                label="Optional Arts Supplement Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={optionalArtsSupplementDeadline}
                onChange={(e) => setOptionalArtsSupplementDeadline(e.target.value)}
              />
            </>
          }

        </Stack>
      </Container>

      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default InstitutionDegreeForm;